import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"; // Import edit and delete icons from react-icons
import { get_all_services, updateServiceStatus, getVehicleType, deleteService } from "../../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";

const ServiceMangement = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const all_services = useSelector((state) => state.auth.all_services);
  const vehicleTypes = useSelector((state) => state.auth.vehicle_types);
  const loading = useSelector((state) => state.auth.loading);

  console.log(all_services, "allservices");

  const CreateService = () => {
    const addVendorPath = "/CreateService";
    navigate(addVendorPath);
  };

  const EditService = (id) => {
    navigate(`/EditService/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteService(token, id));
  };

  useEffect(() => {
    if (token) {
      dispatch(get_all_services(token));
      dispatch(getVehicleType(token));// Fetch the services when the token is available
    }
  }, [token, dispatch, loading]);

  // Extract the prices from the amounts array

  // Filter active services based on `vehicleTypeId.status`
  // Create a new array with filtered pricings within each service
  const activeServices = all_services && all_services?.map((service) => ({
    ...service,
    pricings: service.pricings?.filter(
      (pricing) => pricing.vehicleTypeId?.status === true
    ) || []
  }));

  console.log(activeServices);

  const services = activeServices.map((service) => {
    const pricingMap = service.pricings.reduce((acc, p) => {
      acc[p.vehicleTypeId._id] = p.vehicleTypeId?.status ? p.amount : "N/A";
      return acc;
    }, {});

    return {
      ...service,
      pricingMap,
    };
  });



  const handleToggleStatus = (serviceId, currentStatus) => {
    // Optimistically update the UI by toggling the status
    const newStatus = !currentStatus;
    // Dispatch action to update status in the database
    dispatch(updateServiceStatus(serviceId, newStatus));
  };

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="On Demand Service Management" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={CreateService}
          >
            Create New
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        <DatatableSection>
          <div className="overflow-x-auto">
            <table
              id="serviceTable"
              className="min-w-full text-left text-sm font-light"
            >
              <thead className="bg-gray-200">
                <tr className="whitespace-nowrap">
                  <th className="py-3 px-4">Service ID</th>
                  <th className="py-3 px-4">Service Name</th>
                  <th className="py-3 px-4">Service Category</th>

                  {/* Check if all_services has data before accessing the first element */}
                  {vehicleTypes && vehicleTypes.length > 0 ?
                    (vehicleTypes.map((type) => (
                      <th className="py-3 px-4">{type.name}</th>))
                    ) : (
                      <th className="py-3 px-4">Vehicle Type</th>
                    )}

                  <th className="py-3 px-4">Status</th>
                  <th className="py-3 px-4">Action</th>
                </tr>
              </thead>

              <tbody>
                {services.map((service, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-4 px-6">{index + 1}</td>
                    <td className="py-4 px-6">{service.name}</td>
                    <td className="py-4 px-6">{service.category || "N/A"}</td>

                    {/* Render pricings based on vehicleTypes */}
                    {vehicleTypes.map((type) => (
                      <td key={type._id} className="py-4 px-6">
                        {service.pricingMap[type._id] || "N/A"}
                      </td>
                    ))}

                    {/* Status Toggle */}
                    <td className="py-4 px-6">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={service.status}
                          className="sr-only peer"
                          onChange={() => handleToggleStatus(service._id, service.status)}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-black"></div>
                        <span className="absolute inset-y-0 left-0 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-5"></span>
                      </label>
                    </td>

                    {/* Action Buttons */}
                    <td className="py-4 px-6 flex space-x-2">
                      <button
                        className="bg-white border border-black text-black px-2 py-1 rounded flex items-center"
                        onClick={() => EditService(service._id)}
                      >
                        <AiOutlineEdit className="mr-1 text-black" />
                      </button>
                      <button
                        className="bg-white border border-black text-black px-2 py-1 rounded flex items-center"
                        onClick={() => handleDelete(service._id)}
                      >
                        <AiOutlineDelete className="mr-1 text-black" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>


            </table>
          </div>
        </DatatableSection>
      </MainSection >
    </>
  );
};

export default ServiceMangement;
