import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import {
  addEnquiry,
  getAllVehicles,
  getPhoneUser,
  getActiveServices,
} from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

function AddEnquiry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state
  //const all_vehicles = useSelector((state) => state.auth.all_vehicles);
  const all_services = useSelector((state) => state.auth.active_services);
  const userByPhone = useSelector((state) => state.auth.userByPhone);
  const token = localStorage.getItem("token");

  const today = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toTimeString().slice(0, 5);

  // Separate state for customer details
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    addresses: [],
  });

  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState({
    vehicleName: "",
    vehicleNumber: "",
    vehicleColor: "",
  });

  const packageServices = [
    { _id: "quickwash", name: "Quick Wash" },
    { _id: "premiumwash", name: "Premium Wash" },
  ];

  const [formData, setFormData] = useState({
    date: "",
    enquiryType: "",
    phoneNumber: "",
    latitude: "",
    longitude: "",
    customerType: "",
    referenceType: "",
    vehicleName: "",
    vehicleModel: "",
    vehicleColor: "",
    vehicleNumber: "",
    serviceType: "", // Stores the selected serviceType ID
    desiredDate: "",
    desiredTime: "",
  });

  const [filteredServices, setFilteredServices] = useState([]); // Filtered services list
  const [error, setError] = useState(null); // To manage form validation errors
  const [showModal, setShowModal] = useState(false);


  // Fetch vehicles and services when token is available

  useEffect(() => {
    // Set today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];
    setFormData((prevData) => ({ ...prevData, date: today }));
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getAllVehicles(token)); // Fetch all vehicles
      dispatch(getActiveServices(token)); // Fetch all services
    }
  }, [token, dispatch]);

  // Update vehicle options when userByPhone data is fetched
  useEffect(() => {
    if (userByPhone && userByPhone.vehicles) {
      setVehicleOptions(userByPhone.vehicles);
      setCustomerDetails({
        customerName: userByPhone.name || "",
        addresses: userByPhone.addresses || [],   //// remove [0] after adding multiple address fields in form
      });
    }
  }, [userByPhone]);

  // Handle input changes for form data
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Handle customer details input changes
  const handleCustomerDetailsChange = (e) => {
    const { id, value } = e.target;
    setCustomerDetails({ ...customerDetails, [id]: value });
  };

  // Handle vehicle selection
  const handleVehicleChange = (e) => {
    const selectedVehicleId = e.target.value;
    const selectedVehicle = vehicleOptions.find(
      (vehicle) => vehicle.vehicleId.maker === selectedVehicleId
    );
    if (selectedVehicle) {
      setSelectedVehicleDetails({
        vehicleName: selectedVehicle.vehicleId._id,
        vehicleNumber: selectedVehicle.vehicleNumber,
        vehicleColor: selectedVehicle.vehicleColors[0] || "",
      });
      setFormData({
        ...formData,
        vehicleName: selectedVehicle.vehicleId.maker,
        vehicleModel: selectedVehicle.makeAndModel,
        vehicleColor: selectedVehicle.vehicleColors[0] || "",
        vehicleNumber: selectedVehicle.vehicleNumber,
      });
    }
  };

  // Handle service type selection
  const handleServiceChange = (e) => {
    const selectedServiceId = e.target.value;
    setFormData({ ...formData, serviceType: selectedServiceId });
  };

  // Fetch user by phone number on blur
  // const handlePhoneNumberBlur = () => {
  //   if (formData.phoneNumber) {
  //     dispatch(getPhoneUser({ phoneNumber: formData.phoneNumber }));
  //   }
  // };

  const handlePhoneNumberBlur = async () => {
    if (formData.phoneNumber) {
      const response = await dispatch(getPhoneUser({ phoneNumber: formData.phoneNumber }));

      if (response.status === 404) {
        setError(response.message);
        setShowModal(true); // Show modal if user not found
      }
    }
  };

  const handleAddressChange = (e) => {
    const selectedAddress = e.target.value;
    const address = customerDetails.addresses.find(
      (addr) => addr.address === selectedAddress
    );
    if (address) {
      setFormData((prevData) => ({
        ...prevData,
        address: selectedAddress,
        latitude: address.latitude,
        longitude: address.longitude,
      }));
    }
  };

  useEffect(() => {
    if (formData.enquiryType === "package") {
      setFilteredServices(packageServices); // Set package services if "package" is selected
    } else {
      setFilteredServices(all_services); // Set all ondemand services if "ondemand" is selected
    }
  }, [formData.enquiryType, all_services]);


  const handleCreateUser = () => {
    setShowModal(false);
    navigate("/CreateUser");
  };


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !customerDetails.customerName ||
      !formData.phoneNumber ||
      !formData.latitude ||
      !formData.longitude ||
      !formData.serviceType
    ) {
      setError(
        "Customer Name, Phone Number, Latitude, Longitude, and Service Type are required"
      );
      return;
    }

    const payload = {
      ...formData,
      customerName: customerDetails.customerName, // Include customerName from separate state
      address: formData.address, // Include address from separate state
      vehicleName: selectedVehicleDetails.vehicleName,
      vehicleNumber: selectedVehicleDetails.vehicleNumber,
      vehicleColor: selectedVehicleDetails.vehicleColor,
      desiredDate: `${formData.desiredDate}T${formData.desiredTime}`,
    };

    console.log(payload, "payload");

    dispatch(addEnquiry(payload));

    setTimeout(() => {
      navigate("/Enquiry");
    }, 3000);
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New Enquiry" />
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSubmit}>
          {/* Container for two-column layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="container text-start">
              <div className="my-5">
                <label
                  htmlFor="date"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Enquiry Date
                </label>
                <input
                  type="date"
                  id="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Select enquiry date"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  onBlur={handlePhoneNumberBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter mobile number"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="customerName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Customer Name
                </label>
                <input
                  readOnly
                  type="text"
                  id="customerName"
                  value={customerDetails.customerName}
                  onChange={handleCustomerDetailsChange} // Update customer details state
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter customer name"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Address
                </label>
                <select
                  id="address"
                  value={formData.address}
                  onChange={handleAddressChange} // Update address state
                  //onBlur={(e) => handleAddressBlur(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                >
                  <option value="">Select Address</option>
                  {customerDetails.addresses.map((address, index) => (
                    <option key={index} value={address.address}>
                      {address.address}
                    </option>
                  ))}
                </select>
              </div>


              <div className="mb-5">
                <label
                  htmlFor="latitude"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Latitude
                </label>
                <input
                  type="text"
                  id="latitude"
                  value={formData.latitude}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter latitude"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="longitude"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Longitude
                </label>
                <input
                  type="text"
                  id="longitude"
                  value={formData.longitude}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter longitude"
                  required
                />
              </div>

              {/*  <div className="mb-5">
                <label
                  htmlFor="customerType"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Customer Type
                </label>
                <select
                  id="customerType"
                  value={formData.customerType}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select customer type</option>
                  <option value="New">New</option>
                  <option value="Existing">Existing</option>
                </select>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="referenceType"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Reference Type
                </label>
                <select
                  id="referenceType"
                  value={formData.referenceType}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select reference type</option>
                  <option value="Referral">Referral</option>
                  <option value="Online">Online</option>
                </select>
              </div>*/}

            </div>

            {/* Right Column */}
            <div className="container text-start">
              <div className="my-5">
                <label
                  htmlFor="vehicleName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Vehicle Name
                </label>
                <select
                  id="vehicleName"
                  value={formData.vehicleName}
                  onChange={handleVehicleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select Vehicle</option>
                  {vehicleOptions.map((vehicle) => (
                    <option
                      key={vehicle.vehicleId._id}
                      value={vehicle.vehicleId.maker}
                    >
                      {vehicle.vehicleId.maker}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="vehicleColor"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Vehicle Color
                </label>
                <input
                  type="text"
                  id="vehicleColor"
                  value={selectedVehicleDetails.vehicleColor}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter vehicle color"
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="vehicleNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Vehicle Number
                </label>
                <input
                  type="text"
                  id="vehicleNumber"
                  value={selectedVehicleDetails.vehicleNumber}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter vehicle number"
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="customerType"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Enquiry Type
                </label>
                <select
                  id="enquiryType"
                  value={formData.enquiryType}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select Enquiry Type</option>
                  <option value="ondemand">On Demand</option>
                  <option value="package">Package</option>
                </select>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="serviceType"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Service Type
                </label>
                <select
                  id="serviceType"
                  value={formData.serviceType}
                  onChange={handleServiceChange}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select service type</option>
                  {filteredServices.map((service) => (
                    <option key={service._id} value={service._id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="desiredDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Desired Date
                </label>
                <input
                  type="date"
                  id="desiredDate"
                  value={formData.desiredDate}
                  min={today}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="desiredTime"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Desired Time
                </label>
                <input
                  type="time"
                  id="desiredTime"
                  value={formData.desiredTime}
                  min={formData.desiredDate === today ? currentTime : undefined}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
            </div>
          </div>


          {/* Modal */}
          {showModal && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 50,
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  maxWidth: "500px",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <h3 style={{ fontSize: "1.25rem", marginBottom: "1rem", color: "#333" }}>
                  {error}
                </h3>
                <button
                  onClick={handleCreateUser}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Create User
                </button>
                {/* <button
                  onClick={() => setShowModal(false)}
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button> */}
              </div>
            </div>
          )}


          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create New Enquiry
            </button>
          </div>
        </form>
      </MainSection>
    </div >
  );
}

export default AddEnquiry;

