import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { addVehicle, getVehicleType } from "../../redux/actions/authActions"; // Correct action names
import { useLocation, useNavigate } from "react-router-dom";

function AddNewVehicle() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token"); // Correct capitalization of localStorage
  const navigate = useNavigate();
  // State to store vehicle colors and form data
  const [vehicleColors, setVehicleColors] = useState([""]);
  const [formData, setFormData] = useState({
    name: "",
    maker: "",
    model: "",
    selectedVehicleType: "",
  });

  // Fetch vehicle types on component mount and store them in Redux
  useEffect(() => {
    if (token) {
      dispatch(getVehicleType(token));
    }
  }, [token, dispatch]);

  // Get vehicle types from the Redux store using useSelector
  const vehicleTypes = useSelector((state) => state.auth.vehicle_types);
  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle color input change
  const handleColorChange = (index, value) => {
    const newColors = [...vehicleColors];
    newColors[index] = value;
    setVehicleColors(newColors);
  };

  // Function to add more color inputs
  const handleAddColor = () => {
    setVehicleColors([...vehicleColors, ""]);
  };

  // Function to remove a color input
  const handleRemoveColor = (index) => {
    const newColors = vehicleColors.filter((_, i) => i !== index);
    setVehicleColors(newColors);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the final data to submit
    const data = {
      model: formData.model,
      maker: formData.maker,
      vehicleColors,
      vehicleTypeId: formData.selectedVehicleType, // The selected vehicle type ID
    };

    console.log(data, "data");

    // Dispatch addNewVehicle action with the form data
    dispatch(addVehicle(data));

    // Wait for 3 seconds (3000 milliseconds) before navigating to VehicleManagement
    setTimeout(() => {
      navigate("/VehicleManagement");
    }, 3000); // 3000ms = 3 seconds
  };

  return (
    <div>
      <Sidebar />
      <MainSection>
        <SectionHeader title="Create New Vehicle" />

        {/* Form Section */}
        <div className="container mx-auto text-start mt-6">
          {/* Adjust width for PC view - Left-aligned */}
          <div className="w-full md:w-1/2 md:ml-0">
            {/* Vehicle Make & Model Section */}
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Maker
              </label>
              <input
                type="text"
                name="maker"
                value={formData.maker}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Enter vehicle maker"
              />
            </div>

            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Model
              </label>
              <input
                type="text"
                name="model"
                value={formData.model}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Enter vehicle model"
              />
            </div>

            {/* Vehicle Type Dropdown */}
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Type
              </label>
              <select
                name="selectedVehicleType"
                value={formData.selectedVehicleType}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              >
                <option value="">Select Vehicle Type</option>
                {vehicleTypes.map((type) => (
                  <option key={type._id} value={type._id}>
                    {type.name} {/* Assuming each vehicle type has a 'name' */}
                  </option>
                ))}
              </select>
            </div>

            {/* Vehicle Colors Section */}
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Color
              </label>

              {/* Dynamic Color Input Fields */}
              {vehicleColors.map((color, index) => (
                <div key={index} className="flex items-center gap-2 mb-3">
                  <select
                    value={color}
                    onChange={(e) => handleColorChange(index, e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="">Select Color</option>
                    <option value="Black">Black</option>
                    <option value="Blue">Blue</option>
                    <option value="Grey">Grey</option>
                    <option value="White">White</option>
                    <option value="Red">Red</option>
                    <option value="Orange">Orange</option>
                    <option value="Maroon">Maroon</option>
                  </select>
                  {/* Remove Button */}
                  {index > 0 && (
                    <AiOutlineClose
                      className="text-black cursor-pointer"
                      onClick={() => handleRemoveColor(index)}
                    />
                  )}
                </div>
              ))}
              {/* Add More Colors Button */}
              <Button
                className="flex items-center bg-black text-white hover:bg-[#681312] hover:text-white border border-transparent"
                onClick={handleAddColor}
              >
                <PlusOutlined /> Add
              </Button>
            </div>

            {/* Submit Button */}
            <div className="mt-5">
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-blue-700"
              >
                Create Vehicle
              </button>
            </div>
          </div>{" "}
          {/* End of responsive width container */}
        </div>
      </MainSection>
    </div>
  );
}

export default AddNewVehicle;
