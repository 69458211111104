import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { getVehicleType, addService } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function CreateService() {
  const token = localStorage.getItem("token");
  const vehicleTypes = useSelector((state) => state.auth.vehicle_types); // Fetch vehicle types from Redux state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for service form inputs
  const [serviceData, setServiceData] = useState({
    name: "", // Service name
    time: "", // Service time
    category: "", // Category (e.g., Doorstep Car Wash)
    pricings: [], // Amounts object to store vehicle type id and corresponding amount
  });

  // Fetch vehicle types on component mount
  useEffect(() => {
    if (token) {
      dispatch(getVehicleType(token));
    }
  }, [token, dispatch]);

  // Handle input change for name, time, and category
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setServiceData({ ...serviceData, [id]: value });
  };

  // Handle amount change for each vehicle type
  const handleAmountChange = (vehicleTypeId, value) => {
    console.log(vehicleTypeId, "vehicle typ id");
    setServiceData({
      ...serviceData,
      pricings: { ...serviceData.pricings, [vehicleTypeId]: value }, // Update amounts object dynamically
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare payload in the expected format for the backend
    const payload = {
      name: serviceData.name,
      time: parseInt(serviceData.time), // Convert time to a number
      category: serviceData.category, // Include category in the payload
      pricings: Object.keys(serviceData.pricings).map((vehicleTypeId) => ({
        vehicleTypeId,
        amount: parseFloat(serviceData.pricings[vehicleTypeId]), // Convert amount to number
      })),
    };

    console.log(payload, "payload");
    // Dispatch addService action with payload
    dispatch(addService(payload));
    setTimeout(() => {
      navigate("/ServiceMangement");
    }, 3000); // 3000ms = 3 seconds
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New On Demand Service" />
        <hr className="my-4" />

        {/* Left-aligned container for the form with 50% width */}
        <div className="w-1/2 mt-5">
          <div className="container text-start">
            {/* Service Name Input */}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Service Name
              </label>
              <input
                type="text"
                id="name"
                value={serviceData.name}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter service name"
                required
              />
            </div>

            {/* Service Time Input */}
            <div className="mb-4">
              <label
                htmlFor="time"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Time (in minutes)
              </label>
              <input
                type="number"
                id="time"
                value={serviceData.time}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Enter time in minutes"
                required
              />
            </div>

            {/* Service Category Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Service Category
              </label>
              <select
                id="category"
                value={serviceData.category}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled selected>
                  Select service category
                </option>
                <option value="Doorstep Car Wash">Doorstep Car Wash</option>
                <option value="Exterior Detailing">Exterior Detailing</option>
                <option value="Interior Detailing">Interior Detailing</option>
                <option value="Glass Detailing">Glass Detailing</option>
              </select>
            </div>

            {/* Vehicle Type Amounts Input */}
            {vehicleTypes &&
              vehicleTypes.map((vehicleType) => (
                <div className="mb-4" key={vehicleType._id}>
                  <label
                    htmlFor={`amount-${vehicleType._id}`}
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {vehicleType.name} Pricing
                  </label>
                  <input
                    type="number"
                    id={`amount-${vehicleType._id}`}
                    value={serviceData.pricings[vehicleType._id] || ""}
                    onChange={(e) =>
                      handleAmountChange(vehicleType._id, e.target.value)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                    placeholder={`Enter ${vehicleType.name} pricing`}
                    required
                  />
                </div>
              ))}

            {/* Submit Button */}
            <div className="flex justify-start mt-6">
              <button
                type="submit"
                onClick={handleSubmit} // Call handleSubmit on form submission
                className="py-3 px-6 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </MainSection>
    </div>
  );
}

export default CreateService;
