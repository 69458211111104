import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom"

function ViewPackageUser() {

  const { id } = useParams();

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="View Details" />
        <hr />

        {/* Top Fields for Customer Name and Mobile Number */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-5">
          <div>
            <label htmlFor="customer-name" className="block mb-2 text-left  text-sm font-medium text-gray-900">
              Customer Name
            </label>
            <input
              type="text"
              id="customer-name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter customer name"
            />
          </div>
          <div>
            <label htmlFor="mobile-number" className="block mb-2 text-sm text-left font-medium text-gray-900">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter mobile number"
            />
          </div>
        </div>

        {/* Main Container for Package and Schedule Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

          {/* Left Section: Package Details */}
          <div className="bg-gray-100 p-5 rounded-lg">
            <h2 className="text-lg font-semibold mb-4 text-left">Package Details</h2>

            {/* Table for Vehicle Details */}
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow rounded-lg">
                <thead>
                  <tr className="bg-gray-100 border-b-2 border-gray-300">
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Vehicle Name</th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Vehicle Number</th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Schedule Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-2 text-sm font-medium">VW Polo</td>
                    <td className="px-4 py-2 text-sm text-gray-600">KA 01 AA 1234</td>
                    <td className="px-4 py-2">
                      <button className="bg-black text-white py-1 px-3 rounded-lg flex items-center">
                        View
                        <AiOutlineEye className="ml-0" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 text-sm font-medium">VW Golf</td>
                    <td className="px-4 py-2 text-sm text-gray-600">KA 01 AA 1234</td>
                    <td className="px-4 py-2">
                      <button className="bg-black text-white py-1 px-3 rounded-lg flex items-center">
                        View
                        <AiOutlineEye className="ml-0" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


          {/* Right Section: Schedule Details */}
          <div className="bg-gray-100 p-5 rounded-lg">
            <h2 className="text-lg font-semibold mb-4 text-left">Schedule Details</h2>

            {/* Table for Schedule Details */}
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow rounded-lg">
                <thead>
                  <tr className="bg-gray-100 border-b-2 border-gray-300">
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Schedule</th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Washboy Name</th>
                    <th className="px-4 py-2 text-left font-semibold text-gray-600">Time Slot</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(4)].map((_, index) => (
                    <tr key={index} className="border-b">
                      <td className="px-4 py-2 text-sm text-gray-800">
                        <span>2024-02-02</span>
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-800">
                        <span>John Doe</span>
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-800">
                        <span>10:00 am</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


        </div>
      </MainSection>
    </div>
  );
}

export default ViewPackageUser;
