import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"; // Import the icons from react-icons
import { useDispatch, useSelector } from "react-redux";
import { getPackageUsers } from "../../redux/actions/authActions"



const PackageUserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const packageUsers = useSelector((state) => state.auth.packageUsers);

  const handleAddpackageuser = () => {
    const addVendorPath = "/Package_users/AddPackageUser";
    navigate(addVendorPath);
  };

  const EditpackageUsers = (id) => {
    navigate(`/EditpackageUsers/${id}`);
  };

  const ViewpackageUsers = (id) => {
    navigate(`/View_packageUsers/${id}`);
  };


  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
      autoWidth: false, // Prevents fixed width setting by DataTables
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getPackageUsers(token));
    }
  }, [])



  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Package Users" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddpackageuser}
          >
            Create New
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Full-width table container */}
        <DatatableSection className="w-full overflow-x-auto">
          <div className="overflow-x-auto">
            <table id="vendorTable" className="display w-full">
              <thead>
                <tr>
                  <th>Purchase Date</th>
                  <th>Expiry Date</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                  <th>Premium Wash</th>
                  <th>Quick Wash</th>
                  <th>Name</th>
                  <th>Mobile No</th>
                  <th>Vehicle Name</th>
                  <th>Vehicle No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {packageUsers && packageUsers.map((item, index) => (
                  <tr key={index}>
                    <td>{new Date(item.vehicles[0].purchaseDate
                    ).toLocaleString("en-US", {
                      /*  hour: "2-digit",
                       minute: "2-digit",
                       hour12: true, */
                      day: "numeric",
                      month: "short",
                      year: "numeric"
                    })}</td>
                    <td>{new Date(new Date(item.vehicles[0].purchaseDate).getTime() + 30 * 24 * 60 * 60 * 1000
                    ).toLocaleString("en-US", {
                      /* hour: "2-digit",
                      minute: "2-digit",
                      hour12: true, */
                      day: "numeric",
                      month: "short",
                      year: "numeric"
                    })}</td>
                    <td>{new Date(item.vehicles[0].paymentDate
                    ).toLocaleString("en-US", {
                      /* hour: "2-digit",
                      minute: "2-digit",
                      hour12: true, */
                      day: "numeric",
                      month: "short",
                      year: "numeric"
                    })}</td>
                    {item.vehicles.map((vehicle) => (
                      <>
                        <td>{vehicle.amount}</td>
                        <td>{vehicle.premiumWash}</td>
                        <td>{vehicle.quickWash}</td>
                        <td>{item.name}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{vehicle.vehicleName.model}</td>
                        <td>{vehicle.vehicleNumber}</td>
                      </>
                    ))}
                    {/* <td>{item.vehicles[0].amount}</td>
                    <td>{item.vehicles[0].premiumWash}</td>
                    <td>{item.vehicles[0].quickWash}</td>
                    <td>{item.name}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item?.vehicles[0].vehicleName.model}</td>
                    <td>{item?.vehicles[0].vehicleNumber}</td> */}
                    <td className="flex items-center space-x-2">
                      <button className="bg-black text-white px-1 py-1 rounded flex items-center" onClick={() => ViewpackageUsers(item._id)}>
                        <AiOutlineEye className="ml-0" />
                      </button>
                      <button className="bg-black text-white px-1 py-1 rounded flex items-center" onClick={() => EditpackageUsers(item._id)}>
                        <AiOutlineEdit className="ml-0" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default PackageUserManagement;
