import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai"; // Import the eye icon from react-icons
import { MdFilterList, MdFileUpload } from "react-icons/md"; // Import the necessary icons
import { IoIosCalendar } from "react-icons/io"; // Import calendar icon
import { useSelector, useDispatch } from "react-redux"
import { getAllPackageBookings } from "../../redux/actions/authActions";
import { AiOutlineDelete } from "react-icons/ai"; // Import the edit and delete icons

/* const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    bookingId: `COC${i}023`,
    customerName: `Customer Name`,
    mobileNo: "9123456789",
    address: "Lorem ipsum dolor sit amet...",
    vehicleName: ["Renault Duster", "Ford Figo", "Fiat Punto", "Tata Harrier", "Jeep Compass", "Honda City"][i % 6],
    vehicleNo: ["KA 41 Z 5493", "KA 55 AM 4395", "KA 01 MJ 1456"][i % 3],
    time: "7:00 a.m.",
    serviceName: "Service Name Goes Here",
  });
} */



const PackageBookingManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CreateBooking = () => {
    const addVendorPath = "/CreatePackageBooking";
    navigate(addVendorPath);
  };

  const Viewpackagebooking = () => {
    const addVendorPath = "/Viewpackagebooking";
    navigate(addVendorPath);
  };

  const token = localStorage.getItem("token");
  const allPackageBookings = useSelector((state) => state.auth.allPackageBookings);

  useEffect(() => {
    if (token) {
      dispatch(getAllPackageBookings(token));
    }
  }, [token, dispatch]);

  // Cleanup function to destroy the DataTable when the component unmounts
  /*   useEffect(() => {
      if (allPackageBookings.length > 0) {
        $("#bookingTable").DataTable();
      }
    }, [allPackageBookings]); */

  useEffect(() => {
    console.log(allPackageBookings);
  }, [allPackageBookings])

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Package Bookings" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={CreateBooking}
          >
            Create Booking
          </button>
        </div>

        {/* Form and Actions Bar Matching Provided Image */}
        <div className="flex flex-wrap items-center justify-between mb-6">
          {/* Left Section - Date Picker, Washboy Dropdown, and Search Bar */}
          <div className="flex items-center gap-4 flex-wrap">
            {/* Date Picker */}
            <div className="relative">
              <input
                type="date"
                className="py-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Select Date"
              />
              {/* <IoIosCalendar className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 pointer-events-none" /> */}
            </div>


            {/* Washboy Dropdown */}
            <div className="relative">
              <select
                className="py-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled selected>
                  Select Washboy
                </option>
                <option value="Washboy 1">Washboy 1</option>
                <option value="Washboy 2">Washboy 2</option>
                <option value="Washboy 3">Washboy 3</option>
              </select>
            </div>

            {/* Search Bar */}
            <form className="relative max-w-xs">
              <input
                type="search"
                id="default-search"
                className="block w-full py-2 pe-10 ps-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search"
                required
              />
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
            </form>
          </div>

          {/* Right Section - Filter and Export Buttons */}
          <div className="flex gap-2 mt-4 md:mt-0">
            <button
              className="flex items-center justify-center px-4 py-2 bg-white text-black border border-black rounded-lg hover:bg-gray-100 text-sm"
            >
              <MdFilterList className="mr-2" />
              Filter
            </button>

            <button
              className="flex items-center justify-center px-4 py-2 bg-white text-black border border-black rounded-lg hover:bg-gray-100 text-sm"
            >
              <MdFileUpload className="mr-2" />
              Export
            </button>
          </div>
        </div>


        {/* Table structure matching the provided image */}
        <DatatableSection>
          <div className="overflow-x-auto">
            <table id="bookingTable" className="display w-full text-left border-collapse" style={{ borderSpacing: "0 10px" }}>
              <thead className="bg-gray-100">
                <tr>
                  <th className="border-b-2 border-gray-300 p-3">Booking ID</th>
                  <th className="border-b-2 border-gray-300 p-3">Customer Name</th>
                  <th className="border-b-2 border-gray-300 p-3">Mobile No</th>
                  <th className="border-b-2 border-gray-300 p-3">Address</th>
                  <th className="border-b-2 border-gray-300 p-3">Vehicle Name</th>
                  <th className="border-b-2 border-gray-300 p-3">Vehicle No</th>
                  <th className="border-b-2 border-gray-300 p-3">Time</th>
                  <th className="border-b-2 border-gray-300 p-3">Service Name</th>
                  <th className="border-b-2 border-gray-300 p-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {allPackageBookings && allPackageBookings.map((item, index) => (
                  <tr key={index} className="bg-white hover:bg-gray-100">
                    <td className="p-3 border-b border-gray-200">{item.id}</td>
                    <td className="p-3 border-b border-gray-200">{item.customerName}</td>
                    <td className="p-3 border-b border-gray-200">{item.phoneNumber}</td>
                    <td className="p-3 border-b border-gray-200">{item.address}</td>
                    <td className="p-3 border-b border-gray-200">{item.vehicleName.maker}</td>
                    <td className="p-3 border-b border-gray-200">{item.vehicleNumber}</td>
                    <td className="p-3 border-b border-gray-200">
                      {new Date(item.startTime).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </td>
                    <td className="p-3 border-b border-gray-200">{item.serviceType}</td>
                    <td className="p-3 border-b border-gray-200">
                      <div className="flex items-center space-x-2">
                        <button className="bg-black text-white px-3 py-1 rounded-full flex items-center" onClick={Viewpackagebooking}>
                          View <AiOutlineEye className="ml-2 text-white" />
                        </button>
                        <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-red-800 flex items-center"
                        >
                          <AiOutlineDelete />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  )
};

export default PackageBookingManagement;