import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { getAllPackages, getPhoneUser, addPackageUser, getOnePackageUser } from "../../redux/actions/authActions"


function AddPackageUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packages = useSelector((state) => state.auth.allPackages);
  const userByPhone = useSelector((state) => state.auth.userByPhone);
  const token = localStorage.getItem("token");
  const { id, id1 } = useParams();
  const packageUser = useSelector((state) => state.auth.packageUser);



  const [formData, setFormData] = useState({
    packageType: "",
    purchaseDate: "",
    amount: "",
    paymentDate: "",
    premiumWashes: "",
    quickWashes: "",
    customerName: "",
    phoneNumber: "",
    vehicleName: "",
    vehicleNumber: "",
    vehicleModel: "",
    vehicleColor: "",
    firstWashDate: "",
    firstwashType: ""
  });

  const [error, setError] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");


  useEffect(() => {
    if (token) {
      dispatch(getOnePackageUser(token, id));
      dispatch(getAllPackages(token));
    }
  }, []);

  useEffect(() => {
    if (packageUser && packageUser.length > 0) {
      const user = packageUser[0]; // Assuming packageUser is an array and you want to access the first element

      // Extract the relevant fields from the model
      const vehicle = user.vehicles[0]; // Assuming there's at least one vehicle
      const vehicleName = vehicle.vehicleName; // The vehicle details

      // Set the formData state with the extracted values
      setFormData({
        packageType: user.vehicles[0].package?._id, // Package ID
        purchaseDate: vehicle.purchaseDate.split('T')[0], // Extracting date part only (YYYY-MM-DD)
        amount: vehicle.amount,
        paymentDate: vehicle.paymentDate.split('T')[0], // Extracting date part only (YYYY-MM-DD)
        premiumWashes: vehicle.premiumWash,
        quickWashes: vehicle.quickWash,
        customerName: user.name,
        phoneNumber: user.phoneNumber,
        vehicleName: vehicleName._id, // Assuming you want to store the vehicle ID
        vehicleNumber: vehicle.vehicleNumber,
        vehicleModel: vehicleName.model, // Assuming you want the vehicle model name
        vehicleColor: vehicleName.vehicleColors.join(', '), // Joining color array into a string
        address: userByPhone?.addresses[0],
        cooridinates: userByPhone?.addresses[0].longitude,
      });
    }
  }, [packageUser]);





  const handlePackageChange = (e) => {
    const id = e.target.value;
    console.log(id);
    const selectedPkg = packages.find((pkg) => pkg._id === id);
    console.log(selectedPkg);
    if (selectedPkg) {
      setSelectedPackage(selectedPkg); // Update selected package
      setFormData({
        ...formData,
        packageType: selectedPkg._id,
        premiumWashes: selectedPkg.premiumWash,
        quickWashes: selectedPkg.quickWash// Update the form data with the new package type
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phno = e.target.value;
    setFormData({
      ...formData,
      phoneNumber: phno,
    });

    if (validatePhoneNumber(phno)) {
      setError("");
    } else {
      setError("Please enter a valid 10-digit phone number.");
    }
  };

  useEffect(() => {
    if (formData.phoneNumber && formData.phoneNumber.length === 10 && token) {
      dispatch(getPhoneUser({ phoneNumber: formData.phoneNumber }));
    }
  }, [formData.phoneNumber]);


  useEffect(() => {
    if (userByPhone) {
      setFormData({
        ...formData,
        customerName: userByPhone.name
      })
    }
  }, [formData.phoneNumber, userByPhone])

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/; // Validates 10 digit numbers (no dashes, spaces)
    return phoneRegex.test(number);
  };

  const handleAddressChange = (e) => {
    const add = userByPhone.addresses.find((add) => add.address === e.target.value);
    console.log(add);
    setFormData({
      ...formData,
      address: add?.address,
      coordinates: `${add?.latitude}, ${add?.longitude}`
    })
  };

  useEffect(() => {
    if (userByPhone && selectedPackage) {
      const vehicle = userByPhone.vehicles.find((vehicle) => vehicle.vehicleId._id === formData.vehicleName);
      const newAmount = selectedPackage.pricings.find((p) => p.vehicleTypeId === vehicle.vehicleId.vehicleTypeId).amount;
      console.log(newAmount);
      setFormData({ ...formData, vehicleNumber: vehicle.vehicleNumber, amount: newAmount });
    }
  }, [formData.vehicleName]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = () => {
    const payload = formData;
    console.log(payload);
    dispatch(addPackageUser(token, payload));
  }


  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Edit Package-user profile" />
        <hr />

        {/* Container for two-column layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="container text-start">

            <div className="mb-5">
              <label htmlFor="vehicle-name" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Name
              </label>
              <select
                id="vehicleName"
                onChange={handleChange}
                placeholder="Select vehicle"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option>Select vehicle name</option>
                {userByPhone && userByPhone.vehicles.map((vehicle) => (
                  <option value={vehicle.vehicleId._id} key={vehicle.vehicleId._id}>{vehicle.vehicleId.model}</option>
                ))}
              </select>
            </div>

            <div className="mb-5">
              <label htmlFor="package-type" className="block mb-2 text-sm font-medium text-gray-900">
                Package Type
              </label>
              <select
                id="package-type"
                onChange={handlePackageChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" disabled>Enter package type</option>
                {packages.map((onepackage) => (
                  <option value={onepackage._id} key={onepackage._id}>{onepackage.name}</option>
                ))}

              </select>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* First Line - Two Fields */}
              <div className="mb-0">
                <label htmlFor="date-of-purchase" className="block mb-2 text-sm font-medium text-gray-900">
                  Date Of Purchase
                </label>
                <input
                  type="date"
                  id="purchaseDate"
                  value={
                    formData.purchaseDate
                      ? new Date(formData.purchaseDate).toISOString().split('T')[0]
                      : ""
                  }
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              {/*   <div className="mb-0">
                <label htmlFor="date-of-expiry" className="block mb-2 text-sm font-medium text-gray-900">
                  Date Of Expiry
                </label>
                <input
                  type="date"
                  id="date-of-expiry"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div> */}

              {/* Second Line - Two Fields */}

              <div className="mb-0">
                <label htmlFor="payment-date" className="block mb-2 text-sm font-medium text-gray-900">
                  Payment Date
                </label>
                <input
                  type="date"
                  value={
                    formData.paymentDate
                      ? new Date(formData.paymentDate).toISOString().split('T')[0]
                      : ""
                  }
                  onChange={handleChange}
                  id="paymentDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              {/* Third Line - Two Fields */}
              <div className="mb-5">
                <label htmlFor="premium-washes" className="block mb-2 text-sm font-medium text-gray-900">
                  Premium Washes
                </label>
                <input
                  type="number"
                  id="premium-washes"
                  readOnly
                  value={formData.premiumWashes}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter number"
                />
              </div>

              <div className="mb-5">
                <label htmlFor="quick-washes" className="block mb-2 text-sm font-medium text-gray-900">
                  Quick Washes
                </label>
                <input
                  type="number"
                  readOnly
                  id="quick-washes"
                  value={formData.quickWashes}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter number"
                />
              </div>
            </div>

            <div className="mb-5">
              <label htmlFor="mobile-number" className="block mb-2 text-sm font-medium text-gray-900">
                Mobile Number
              </label>
              <input
                type="text"
                readOnly
                id="mobile-number"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter mobile number"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="customer-name" className="block mb-2 text-sm font-medium text-gray-900">
                Customer Name
              </label>
              <input
                type="text"
                id="customer-name"
                value={formData.customerName}
                disabled
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter customer name"
              />
            </div>



            {/* <div className="mb-5">
              <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">
                Address
              </label>
              <select
                id="address"
                onChange={handleAddressChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter address"
              >
                <option disabled>Select address</option>
                {userByPhone && userByPhone.addresses.map((add) => (
                  <option value={add.address} key={add.address}>{add.address}</option>
                ))}
              </select>
            </div>

            <div className="mb-5">
              <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900">
                Latitude & Longitude
              </label>
              <input
                type="text"
                id="latitude"
                readOnly
                value={formData.coordinates}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter latitude & longitude"
              />
            </div> */}
          </div>

          {/* Right Column */}
          <div className="container text-start">
            {/* <div className="mb-5">
              <label htmlFor="vehicle-name" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Name
              </label>
              <select
                id="vehicleName"
                onChange={handleChange}
                placeholder="Select vehicle"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option>Select vehicle name</option>
                {userByPhone && userByPhone.vehicles.map((vehicle) => (
                  <option value={vehicle.vehicleId._id} key={vehicle.vehicleId._id}>{vehicle.vehicleId.model}</option>
                ))}
              </select>
            </div>
 */}
            {/*   <div className="mb-5">
              <label htmlFor="vehicle-model" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Model
              </label>
              <input
                type="text"
                id="vehicle-model"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle model"
              />
            </div>
 */}
            <div className="mb-5">
              <label htmlFor="vehicle-color" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Color
              </label>
              <input type="text"
                id="vehicle-color"
                value={formData.vehicleColor}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Confirm vehicle colors"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="vehicle-no" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle No
              </label>
              <input
                type="text"
                id="vehicle-no"
                readOnly
                value={formData.vehicleNumber}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle number"
              />
            </div>

            <div className="mb-0">
              <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
                Amount
              </label>
              <input
                type="number"
                id="amount"
                readOnly
                value={formData.amount}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter amount"
              />
            </div>


            {/*  <div className="mb-5">
              <label htmlFor="desired-date" className="block mb-2 text-sm font-medium text-gray-900">
                Desired Date
              </label>
              <input
                type="date"
                id="desired-date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
 */}
            {/*  <div className="mb-5">
              <label htmlFor="desired-time" className="block mb-2 text-sm font-medium text-gray-900">
                Desired Time
              </label>
              <input
                type="time"
                id="desired-time"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div> */}

            {/* <div className="mb-5">
              <label htmlFor="next-wash-type" className="block mb-2 text-sm font-medium text-gray-900">
                Next Wash Type
              </label>
              <select
                id="next-wash-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option >Select wash type</option>
                <option value="1">Wash 1</option>
                <option value="2">Wash 2</option>
              </select>
            </div> */}

            <div className="mb-5">
              <label htmlFor="next-wash-date" className="block mb-2 text-sm font-medium text-gray-900">
                First Wash Date
              </label>
              <input
                type="date"
                id="next-wash-date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            onClick={handleSubmit}
            className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save Changes
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default AddPackageUser;
