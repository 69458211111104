/* eslint-disable no-unused-vars */
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import EnquiryManagement from "./pages/Enquiry-Management/EnquiryManagement";
import AddEnquiry from "./pages/Enquiry-Management/AddEnquiry";

import PackageManagement from "./pages/Package_Management/PackageManagement";
import Addpakage from "./pages/Package_Management/Addpackage";
import PackageUserManagement from "./pages/Package_users/PackageUserManagement";
import AddPackageUser from "./pages/Package_users/AddPackageUser";
import WashBoyManagement from "./pages/Wash_Boy_Management/WashBoyManagement";
import AddWashBoy from "./pages/Wash_Boy_Management/AddWashBoy";
import AllUserManagement from "./pages/All_users/AllUserManagement";
import CreateUser from "./pages/All_users/CreateUser";
import ViewUser from "./pages/All_users/ViewUser";
import VehicleManagement from "./pages/VehicleManagement/VehicleManagement";
import AddNewVehicle from "./pages/VehicleManagement/AddNewVehicle";
import VehicleTypeManagement from "./pages/VehicleType/VehicleTypeManagement";
import CreateVehicleType from "./pages/VehicleType/CreateVehicleType";
import ServiceMangement from "./pages/Service_Management/ServiceMangement";
import CreateService from "./pages/Service_Management/CreateService";
import BookingManagement from "./pages/Bookings/BookingManagement";
import CreateBooking from "./pages/Bookings/CreateBooking";
import EditUser from "./pages/All_users/EditUser";

import EditWashboy from "./pages/Wash_Boy_Management/EditWashboy";
import EditPackage from "./pages/Package_Management/EditPackage";
import EditService from "./pages/Service_Management/EditService";
import EditpackageUsers from "./pages/Package_users/EditpackageUsers";
import EditVehicle from "./pages/VehicleManagement/EditVehicle";
import EditVehicleType from "./pages/VehicleType/EditVehicleType";
import View_packageUser from "./pages/Package_users/View_packageUser";

import PackageBookingManagement from "./pages/Bookings/PackageBookingManagement";
import CreatePackageBooking from "./pages/Bookings/CreatePackageBooking";

import ViewBooking from "./pages/Bookings/ViewBooking";
import Viewpackagebooking from "./pages/Bookings/Viewpackagebooking";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/Enquiry" element={<EnquiryManagement />} />
          <Route path="/Addenquiry" element={<AddEnquiry />} />

          <Route path="/PackageManagement" element={<PackageManagement />} />
          <Route
            path="/Package_Management/Addpackage"
            element={<Addpakage />}
          />
          <Route
            path="/PackageUserManagement"
            element={<PackageUserManagement />}
          />
          <Route
            path="/Package_users/AddPackageUser"
            element={<AddPackageUser />}
          />
          <Route path="/WashBoyManagement" element={<WashBoyManagement />} />
          <Route
            path="/Wash_Boy_Management/AddWashBoy"
            element={<AddWashBoy />}
          />
          <Route path="/AllUserManagement" element={<AllUserManagement />} />
          <Route path="/CreateUser" element={<CreateUser />} />
          <Route path="/VehicleManagement" element={<VehicleManagement />} />
          <Route path="/AddNewVehicle" element={<AddNewVehicle />} />
          <Route
            path="/VehicleTypeManagement"
            element={<VehicleTypeManagement />}
          />
          <Route path="/CreateVehicleType" element={<CreateVehicleType />} />
          <Route path="/ServiceMangement" element={<ServiceMangement />} />
          <Route path="/CreateService" element={<CreateService />} />
          <Route path="/BookingManagement" element={<BookingManagement />} />
          <Route path="/CreateBooking" element={<CreateBooking />} />
          <Route path="/EditWashboy/:id" element={<EditWashboy />} />
          <Route path="/EditPackage/:id" element={<EditPackage />} />
          <Route path="/EditService/:id" element={<EditService />} />
          <Route path="/EditpackageUsers/:id" element={<EditpackageUsers />} />
          <Route path="/EditVehicle/:id" element={<EditVehicle />} />
          <Route path="/EditVehicleType/:id" element={<EditVehicleType />} />

          <Route path="/PackageBookingManagement" element={<PackageBookingManagement />} />
          <Route path="/CreatePackageBooking" element={<CreatePackageBooking />} />
          <Route path="/View_packageUsers/:id" element={<View_packageUser />} />
          <Route path="/ViewUser/:id" element={<ViewUser />} />
          <Route path="/EditUser/:id" element={<EditUser />} />

          <Route path="/ViewBooking" element={<ViewBooking />} />
          <Route path="/Viewpackagebooking" element={<Viewpackagebooking />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
