// src/redux/initialState.js
const initialState = {
  auth: {
    loading: false,
    adminemail: null,
    isAuthenticated: false,
    vehicle_types: [],
    all_vehicles: [],
    enquiries: [],
    services: [],
    all_enquiry: [],
    all_services: [],
    active_services: [],
    get_wash_boy: [],
    user: [],
    allBookings: [],
    userByPhone: null,
    get_one_vehicle: null,
    activeCustomers: [],
    activeCustomerVehicles: [],
    availableWashboys: [],
    allPackages: [],
    packageDetails: [],
    customerEnquiries: [],
    packageUsers: [],
    selectedPkgUser: [],
    allPackageBookings: [],
    oneService: [],
    oneWashboy: []
  },
};

export default initialState;