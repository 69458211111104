import initialState from "../state/initialState.js"; // Import the global initialState

// Use only the "auth" slice of the global initialState
const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, loading: true };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        adminemail: action.payload, // Assuming action.payload contains admin's email or user data
        isAuthenticated: true,
        error: null,
      };

    case "LOGIN_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
      };

    case "ADD_VEHICLE_SUCCESS":
      return {
        ...state,
        loading: false,
        vehicle_types: [...state.vehicle_types, action.payload], // Add the new vehicle to the vehicle_types array
        error: null,
        isAuthenticated: true,
      };

    case "ADD_VEHICLE_FAILURE": // Corrected 'VEHICLE' to 'VEHICLE'
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: true, // User remains authenticated even if adding vehicle fails
      };
    case "GET_VEHICLE_TYPE":
      return {
        ...state,
        loading: false,
        vehicle_types: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };


    case "GET_VEHICLES":
      return {
        ...state,
        loading: false,
        all_vehicles: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "ADD_ENQUIRY_SUCCESS":

      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: true, // User remains authenticated even if adding vehicle fails
      };


    case "ADD_ENQUIRY_FAILURE": // Corrected 'VEHICLE' to 'VEHICLE'
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: true,
      };

    case "GET_VEHICLES":
      return {
        ...state,
        loading: false,
        all_enquiries: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "ADD_ENQUIRY_SUCCESS":
      return {
        ...state,
        loading: false,
        enquiries: [...state.enquiries, action.payload], // Add the new enquiry to the enquiries array
        error: null,
        isAuthenticated: true,
      };
    // ADD_ENQUIRY_FAILURE action
    case "ADD_ENQUIRY_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: true, // User remains authenticated even if adding enquiry fails
      };
    case "GET_SERVICES":
      return {
        ...state,
        loading: false,
        services: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "GET_ALL_ENQUIRY":
      return {
        ...state,
        loading: false,
        all_enquiry: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "ADD_SERVICES":
      return {
        ...state,
        loading: false,
        services: [...state.services, action.payload], // Add the new vehicle to the vehicle_types array
        error: null,
        isAuthenticated: true,
      };
    case "GET_ALL_SERVICES":
      return {
        ...state,
        loading: false,
        all_services: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "EDIT_SERVICE_STATUS":
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: true, // User remains authenticated  
      };

    case "ADD_WASH_BOY":
      return {
        ...state,
        loading: false,
        get_wash_boy: [...state.get_wash_boy, action.payload], // Add the new vehicle to the vehicle_types array
        error: null,
        isAuthenticated: true,
      };

    case "ADD_USER":
      return {
        ...state,
        loading: false,
        user: [...state.user, action.payload], // Add the new vehicle to the vehicle_types array
        error: null,
        isAuthenticated: true,
      };
    case "GET_ALL_USERS":
      return {
        ...state,
        loading: false,
        user: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "GET_ALL_WASH_BOY":
      return {
        ...state,
        loading: false,
        get_wash_boy: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "USER_PHONE":
      return {
        ...state,
        loading: false,
        userByPhone: {
          ...state.userByPhone, // Spread the previous state (if it's an object or array)
          ...action.payload, // Add/merge the new data (payload)
        },
        error: null,
        isAuthenticated: true,
      };
    case "DELETE_VEHICLE_SUCCESS":
      return {
        ...state,
        loading: false,
        vehicles: state.all_vehicles.filter(
          (vehicle) => vehicle.id !== action.payload
        ), // Assuming payload contains the ID of the deleted vehicle
        error: null,
      };

    case "DELETE_VEHICLE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload, // Assuming payload contains the error message
      };
    case "GET_VEHICLE":
      return {
        ...state,
        loading: false,
        get_one_vehicle: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "EDIT_VEHICLE":
      return {
        ...state,
        loading: false,
        get_one_vehicle: action.payload, // Assuming action.payload contains the updated vehicle data
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "GET_ALL_BOOKINGS":
      return {
        ...state,
        loading: false,
        allBookings: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "ADD_BOOKING":
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: true,
      }
    case "GET_ACTIVE_ENQUIRIES_CUSTOMERS":
      return {
        ...state,
        loading: false,
        activeCustomers: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "GET_USER_VEHICLES":
      return {
        ...state,
        loading: false,
        activeCustomersVehicles: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };
    case "GET_AVAILABLE_WASHBOYS":
      return {
        ...state,
        loading: false,
        availableWashboys: action.payload,
        error: null,
        isAuthenticated: true,
      };
    case "ADD_PACKAGE":
      return {
        ...state,
        loading: false,
        allPackages: [...state.allPackages, action.payload],
        error: null,
        isAuthenticated: true
      };
    case "GET_ALL_PACKAGES":
      return {
        ...state,
        loading: false,
        allPackages: action.payload,
        error: null,
        isAuthenticated: true
      }
    case "GET_ONE_PACKAGE":
      return {
        ...state,
        loading: false,
        packageDetails: action.payload,
        error: null,
        isAuthenticated: true
      }
    case "EDIT_PACKAGE":
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true
      }
    case "GET_CUSTOMER_ENQUIRIES":
      return {
        ...state,
        loading: false,
        customerEnquiries: action.payload,
        error: null,
        isAuthenticated: true
      }

    case "GET_ACTIVE_SERVICES":
      return {
        ...state,
        loading: false,
        active_services: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "GET_PACKAGEUSERS":
      return {
        ...state,
        loading: false,
        packageUsers: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }
    case "GET_ONE_PACKAGEUSER":
      return {
        ...state,
        loading: false,
        packageUser: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    case "GET_ACTIVE_PACKAGEUSERS":
      return {
        ...state,
        loading: false,
        activePkgUsers: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    case "GET_PACKAGEUSER_BY_NUMBER":
      return {
        ...state,
        loading: false,
        selectedPkgUser: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }
    case "GET_ALL_PACKAGE_BOOKINGS":
      return {
        ...state,
        loading: false,
        allPackageBookings: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "GET_ONE_SERVICE":
      return {
        ...state,
        loading: false,
        oneService: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      };

    case "GET_ONE_WASHBOY":
      return {
        ...state,
        loading: false,
        oneWashboy: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    case "EDIT_WASHBOY_STATUS":
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    case "EDIT_PACKAGE_STATUS":
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    case "GET_ONE_USER":
      return {
        ...state,
        loading: true,
        oneUser: action.payload, // Assuming action.payload contains an array of vehicle types
        error: null,
        isAuthenticated: true, // User remains authenticated
      }

    default:
      return state;
  }
};

export default authReducer;