import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { useDispatch, useSelector } from "react-redux";
import { getOneWashboy, editWashboy } from "../../redux/actions/authActions";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd"; // For feedback to the user

function EditWashBoy() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    //password: "",
    //confirmPassword: "",
    joinDate: new Date().toISOString().split("T")[0], // Default to current date in YYYY-MM-DD format
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { id } = useParams();

  const washboy = useSelector((state) => state.auth.oneWashboy);

  useEffect(() => {
    if (token && id)
      dispatch(getOneWashboy(token, id));
  }, [token, id]);

  useEffect(() => {
    if (washboy) {
      setFormData({
        name: washboy[0]?.name,
        email: washboy[0]?.email,
        phoneNumber: washboy[0]?.phoneNumber,
        //joinDate: new Date(washboy.joinDate).toISOString().split("T")[0],
      })
    }
  }, [washboy])

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure passwords match
    if (formData.password !== formData.confirmPassword) {
      message.error("Passwords do not match");
      return;
    }

    const payload = {
      ...formData
    };

    await dispatch(editWashboy(token, payload, id)); // Dispatch action to add wash boy
    setTimeout(() => {
      navigate("/WashBoyManagement");
    }, 3000);
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Edit Wash Boy Profile" />
        <hr className="my-4" />

        {/* Form layout */}
        <form onSubmit={handleSubmit}>
          <div className="container max-w-lg text-start">
            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Wash Boy Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter wash boy name"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter email address"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter phone number"
                required
              />
            </div>

            {/* <div className="mb-5">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Set Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Set account password"
                required
              />
            </div> */}

            {/* <div className="mb-5">
              <label
                htmlFor="confirmPassword"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Confirm account password"
                required
              />
            </div> */}

            {/*  <div className="mb-5">
              <label
                htmlFor="status"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Status
              </label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
 */}
            <div className="flex justify-left mt-6">
              <button
                type="submit"
                className="py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </MainSection>
    </div>
  );
}

export default EditWashBoy;
