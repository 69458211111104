import { MdModeEditOutline } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { MdFilterList, MdFileUpload  } from "react-icons/md"; // Import the necessary icons


const SearchBar = ({ addVendorPath, editVendorPath, showEditButton, titleOne }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the search logic here
    console.log("Search triggered");
  };

  const navigate = useNavigate();

  const handleAddVendor = () => {
    navigate(addVendorPath);
  };

  const handleEditVendor = () => {
    navigate(editVendorPath);
  };

  return (
    <div className="block md:flex md:justify-between justify-center items-center py-4 pr-4">
<form className="max-w-md w-full mb-6" onSubmit={handleSubmit}>
  <label
    htmlFor="default-search"
    className="mb-2 text-sm font-medium text-gray-900 sr-only"
  >
    Search
  </label>
  <div className="relative">
    {/* Search Icon positioned at the right end */}
    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
      <svg
        className="w-4 h-4 text-black" // Changed icon color to black
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
        />
      </svg>
    </div>
    <input
      type="search"
      id="default-search"
      className="block w-full py-2 pe-10 ps-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
      placeholder="Search"
      required
    />
  </div>
</form>


      <div className="flex mb-6">
      <button
        className="flex items-center justify-center px-4 py-2 bg-white text-black border border-black rounded hover:bg-gray-100 mr-2 text-sm w-full md:w-auto"
      >
        {/* Filter Icon */}
        <MdFilterList className="mr-2" />
        {/* Filter Text */}
        Filter
      </button>

      <button
        className="flex items-center justify-center px-4 py-2 bg-white text-black border border-black rounded hover:bg-gray-100 mr-2 text-sm w-full md:w-auto"
      >
        <MdFileUpload  className="mr-2" /> {/* Export Icon */}
        Export
      </button>
    
      </div>
    </div>
  );
};

export default SearchBar;
