import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css"; // DataTables CSS
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"; // Import the edit and delete icons
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";


// Sample data to populate the table
const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    typeId: `COC10123`, // Type ID
    creationDate: "02/02/23", // Creation Date
    vehicleTypeName: "Type Name goes here", // Vehicle Type Name
  });
}

const VehicleTypeManagement = () => {
  const navigate = useNavigate();

  //
  const [vehicleTypeList, setVehicleTypeList] = useState(null);

  const CreateVehicleType = () => {
    const addVehiclePath = "/CreateVehicleType";
    navigate(addVehiclePath);
  };

  const EditVehicleType = (id) => {
    navigate(`/EditVehicleType/${id}`);
  };

  const handleDelete = async (_id) => {
    console.log(`Deleting vehicle type with ID: ${_id}`);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;


      const token = localStorage.getItem("token"); // or sessionStorage if you're using that
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token included
          "Content-Type": "application/json", // Add any other headers if necessary
        },
      };
      const response = await axios.delete(
        `${apiUrl}/api/deleteVehicleType/${_id}`,
        config
      );

      console.log(response);
      if (response.status === 200) {

        toast.success(`Vehicle Type deleted successfully`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        getList();
      }
    } catch (error) {
      console.log(error);
      console.error("Error while deleting vehicle:", error.status);
      if (error.status === 400) {
        alert(JSON.stringify(error));
      } else {
        alert("Something went wrong while deleting vehicle type");
      }
    }
  };

  useEffect(() => {
    getList().then(() => { });
  }, []);

  async function getList() {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log(apiUrl, "api url");

      const token = localStorage.getItem("token"); // or sessionStorage if you're using that
      console.log(token, "token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token included
          "Content-Type": "application/json", // Add any other headers if necessary
        },
      };
      const response = await axios.get(`${apiUrl}/api/vehicleTypes`, config);

      console.log(response);
      if (response.status === 200) {
        setVehicleTypeList(response.data);
      }
    } catch (error) {
      // console.error('Error creating vehicle:', error.status);
      if (error.status === 400) {
        alert(JSON.stringify(error));
      } else {
        alert("Something went wrong while creating vehicle type");
      }
    }
  }
  console.log(vehicleTypeList, "vehicle");
  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Vehicle Type" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={CreateVehicleType}
          >
            Create New Type
          </button>
        </div>

        {/* Full-width table container */}
        <div className="w-full overflow-x-auto">
          <table
            id="vehicleTable"
            className="display w-full text-left table-auto border-separate"
            style={{ borderSpacing: "0 10px" }}
          >
            <thead className="bg-gray-100">
              <tr>
                <th className="border-b-2 border-gray-300 p-3">Type ID</th>
                <th className="border-b-2 border-gray-300 p-3">
                  Vehicle Type Name
                </th>
                <th className="border-b-2 border-gray-300 p-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {vehicleTypeList != null &&
                vehicleTypeList.map((item, index) => {
                  return (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="p-3 border-b border-gray-200">
                        {index + 1}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item.name}
                      </td>
                      <td className="p-3 border-b border-gray-200 text-center flex justify-center space-x-2">
                        {/* Edit Button */}
                        <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-gray-700"
                          onClick={() => EditVehicleType(item._id)}
                        >
                          <AiOutlineEdit />
                        </button>
                        {/* Delete Button */}
                        <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-red-800"
                          onClick={() => handleDelete(item._id)}
                        >
                          <AiOutlineDelete />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </MainSection>
    </>
  );
};

export default VehicleTypeManagement;
