import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"; // Import the edit and delete icons from react-icons
import { getAllVehicles, deleteVehicle } from "../../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const VehicleManagement = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const AddNewVehicle = () => {
    const addVehiclePath = "/AddNewVehicle";
    navigate(addVehiclePath);
  };

  // const EditVehicle = () => {
  //   const editVehiclePath = "/EditVehicle"; // Updated the path variable
  //   navigate(editVehiclePath);
  // };

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const all_vehicles = useSelector((state) => state.auth.all_vehicles);

  useEffect(() => {
    if (token) {
      dispatch(getAllVehicles(token)); // Fetch the vehicles when the token is available
    }
  }, [token, dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteVehicle(id));
  };
  const handleNavigate = (id) => {
    navigate(`/EditVehicle/${id}`);
  };

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Vehicle Management" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={AddNewVehicle}
          >
            Create Vehicle
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Full-width table container */}
        <DatatableSection className="w-full overflow-x-auto">
          <div className="overflow-x-auto">
            <table
              id="vehicleTable"
              className="display w-full text-left border-collapse"
              style={{ borderSpacing: "0 10px" }} // Adding row spacing
            >
              <thead className="bg-gray-100">
                <tr>
                  <th className="border-b-2 border-gray-300 p-3">Vehicle ID</th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Creation Date
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Vehicle Maker
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Vehicle Model
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Vehicle Colors
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {all_vehicles && all_vehicles.length > 0 ? (
                  all_vehicles.map((item, index) => (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="p-3 border-b border-gray-200">
                        {index + 1}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {new Date(item?.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item?.maker}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item?.model}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item?.vehicleColors.join(", ")}
                      </td>
                      <td className="p-3 border-b border-gray-200 text-center flex items-center justify-center space-x-2">
                        {/* Edit Button */}
                        <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-gray-700"
                          onClick={() => handleNavigate(item?._id)}
                        >
                          <AiOutlineEdit />
                        </button>
                        {/* Delete Button */}
                        <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-red-800"
                          onClick={() => handleDelete(item?._id)}
                        >
                          <AiOutlineDelete />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center p-3">
                      <p>Loading...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default VehicleManagement;
