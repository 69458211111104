import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { getAvailableWashboys, addPackageBooking, getActivePackageUsers, getPackageUserByNumber } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function Viewpackagebooking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const activePkgUsers = useSelector((state) => state.auth.activePkgUsers);
  const selectedPkgUser = useSelector((state) => state.auth.selectedPkgUser);
  const availableWashboys = useSelector((state) => state.auth.availableWashboys);

  const today = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toTimeString().slice(0, 5);


  // const customerEnquiries = useSelector((state) => state.auth.customerEnquiries);
  const [customerEnquiries, setCustomerEnquiries] = useState([]);
  console.log(activePkgUsers, "Active Customers");
  /*   const activeCustomervehicles = useSelector((state) => state.auth.activeCustomerVehicles);*/

  const [formData, setFormData] = useState({
    customerNumber: "",
    vehicleName: "",
    vehicleNumber: "",
    address: "",
    desiredDate: "",
    desiredTime: "",
    serviceName: ""
  })
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState("");
  const [time, setTime] = useState("");
  const [washboy, setWashboy] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [services, setServices] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(getActivePackageUsers(token)); // Fetch the enquiries when the token is available
    }
  }, [token, dispatch]);

  useEffect(() => {
    // Set today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];

    setFormData((prevData) => ({ ...prevData, desiredDate: today }));
  }, []);

  useEffect(() => {
    if (formData.customerNumber && activePkgUsers) {
      dispatch(getPackageUserByNumber(token, formData.customerNumber));
      setFormData((prevData) => ({
        ...prevData,
        vehicleName: "",
        vehicleNumber: "",
        address: "",
        serviceName: "",
      }));
    }
  }, [formData.customerNumber, activePkgUsers])


  useEffect(() => {
    if (selectedPkgUser) {
      console.log(selectedPkgUser);
    }
  }, [selectedPkgUser]);

  useEffect(() => {
    if (formData.vehicleName && selectedPkgUser) {
      const vehicle = selectedPkgUser?.vehicles.find(vehicle => vehicle.vehicleName.model === formData.vehicleName);
      setSelectedVehicle(vehicle);
      if (vehicle?.premiumWash > 0 && vehicle?.quickWash > 0)
        setServices(["Premium Wash", "Quick Wash"]);
      else if (vehicle?.premiumWash > 0 && vehicle?.quickWash === 0)
        setServices(["Premium Wash"]);
      else setServices(["QuickWash"]);
      setFormData({
        ...formData,
        vehicleName: vehicle?.vehicleName.model,
        vehicleNumber: vehicle?.vehicleNumber,
      })
    }
  }, [formData.vehicleName, selectedPkgUser]);


  useEffect(() => {
    if (selectedEnquiry) {
      setFormData((prevData) => ({
        ...prevData,
        vehicleNumber: selectedEnquiry.vehicleNumber,
        address: selectedEnquiry.address,
        serviceName: selectedEnquiry.serviceType.name,
        time: (selectedEnquiry.desiredDate),
      }))
    }
  }, [selectedEnquiry])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    if (token && formData.desiredDate && formData.desiredTime) {
      const time = new Date(`${formData?.desiredDate}T${formData?.desiredTime}`);
      if (formData.serviceName === "Premium Wash")
        dispatch(getAvailableWashboys(time, 100));
      if (formData.serviceName === "Quick Wash")
        dispatch(getAvailableWashboys(time, 50));
    }
  }, [token, formData.desiredTime, formData.desiredDate]);


  const handleWashboyChange = (e) => {
    const id = e.target.value;
    setWashboy(availableWashboys.find((washboy) => washboy.washboyId === id));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      customerName: selectedPkgUser.name,
      phoneNumber: formData.customerNumber,
      vehicleName: selectedVehicle.vehicleName._id,
      vehicleNumber: formData.vehicleNumber,
      address: formData.address,
      serviceName: formData.serviceName,
    }
    dispatch(addPackageBooking(payload, selectedPkgUser._id, washboy));
    setTimeout(3000, navigate("/PackageBookingManagement"));
  }


  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="View Package Booking" />
        <hr className="my-4" />

        {/* Left-aligned container for the form with 50% width */}
        <div className="w-1/2 mt-5">
          {/* Form Fields Section */}
          <div className="container text-start">
            {/* Customer Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="customerNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Number
              </label>
              <select
                id="customerNumber"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                value={formData.customerNumber}
                onChange={handleInputChange}
                //onBlur={handleCustomerBlur}
                required
              >
                <option value="" disabled select>
                  Select customer number
                </option>
                {activePkgUsers && activePkgUsers.map((customer) => (
                  <option value={customer.phoneNumber} >{customer.phoneNumber}</option>
                ))};
              </select>
            </div>

            {/* Vehicle Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="vehicleName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Name
              </label>
              <select
                id="vehicleName"
                value={formData.vehicleName}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled select>
                  Select vehicle
                </option>
                {
                  selectedPkgUser && selectedPkgUser?.vehicles?.length > 0
                  && selectedPkgUser.vehicles.map((vehicle) => (
                    <option key={vehicle._id} value={vehicle.vehicleName.model}>
                      {vehicle.vehicleName.model}
                    </option>
                  ))
                }
              </select>
            </div>

            {/* Vehicle Number Input */}
            <div className="mb-4">
              <label
                htmlFor="vehicleNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Number
              </label>
              <input
                type="text"
                id="vehicleNumber"
                value={formData.vehicleNumber}
                onChange={handleInputChange}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Vehicle number"
                required
              />
            </div>

            {/* Address Input */}
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Address
              </label>
              <select
                id="address"
                value={formData.address}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled selected>
                  Select service
                </option>
                {selectedPkgUser && selectedPkgUser?.user_Id?.addresses?.map((add) => (
                  <option value={add.address}>{add.address}</option>
                ))}

              </select>
            </div>



            {/* Service Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="serviceName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Service Name
              </label>
              <select
                id="serviceName"
                value={formData.serviceName}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled selected>
                  Select service
                </option>
                {services && services.map((service) => (
                  <option value={service}>{service}</option>
                ))}

              </select>
            </div>


            <div className="mb-5">
              <label
                htmlFor="desiredDate"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Desired Date
              </label>
              <input
                type="date"
                id="desiredDate"
                value={formData.desiredDate}
                min={today}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="desiredTime"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Desired Time
              </label>
              <input
                type="time"
                id="desiredTime"
                value={formData.desiredTime}
                min={formData.desiredDate === today ? currentTime : undefined}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="time"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Wash Boy
              </label>
              <select
                id="washboy"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
                value={washboy.washboyId}
                onChange={handleWashboyChange}
              >
                <option value="" disabled selected>
                  Select washboy
                </option>
                {availableWashboys.map((washboy) => (
                  <option value={washboy.washboyId} >{washboy.name} Closest-time: {new Date(washboy.closestAvailableStartTime).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true
                  })}</option>
                ))};
              </select>
            </div>

            {/* Submit Button */}
            {/* <div className="flex justify-start mt-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="py-3 px-6 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </MainSection>
    </div>
  );
}

export default Viewpackagebooking;
