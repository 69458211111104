import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { editPackage, getVehicleType, getOnePackage } from "../../redux/actions/authActions"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";


function Editpackage() {
  const { id } = useParams();
  const vehicleTypes = useSelector((state) => state.auth.vehicle_types);
  const [formData, setFormData] = useState({ name: "", duration: "", pricings: [], premiumWash: "", quickWash: "" })
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packageDetail = useSelector((state) => state.auth.packageDetails);
  const packageDetails = packageDetail[0];
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    if (token) {
      dispatch(getVehicleType(token));
      dispatch(getOnePackage(token, id)).then(() => setLoading(false));
    }
  }, [token, id, dispatch]);

  useEffect(() => {
    if (!loading && packageDetails) {
      setFormData({
        name: packageDetails.name || "",
        duration: packageDetails.duration || "",
        pricings: packageDetails.pricings || [],
        premiumWash: packageDetails.premiumWash || "",
        quickWash: packageDetails.quickWash || ""
      });
    }
  }, [loading, packageDetails]);

  const handlePriceChange = (vehicleTypeId, amount) => {
    setFormData((prevData) => {
      const updatedPricings = prevData.pricings.map((pricing) =>
        pricing.vehicleTypeId === vehicleTypeId
          ? { ...pricing, amount }
          : pricing
      );

      if (!updatedPricings.some((pricing) => pricing.vehicleTypeId === vehicleTypeId)) {
        updatedPricings.push({ vehicleTypeId, amount });
      }

      return { ...prevData, pricings: updatedPricings };
    });
  };


  const handleSubmit = () => {
    dispatch(editPackage(formData, id));
    setTimeout(navigate('/PackageManagement'), 3000);
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Edit Package" />
        <hr />

        {/* Container for two-column layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-5">
          {/* Left Column */}
          <div className="container text-start">


            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Package Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter package name"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="mobile"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Package Duration
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                name="duration"
                value={formData.duration}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))}
              >
                <option select>Select package duration</option>
                <option value="30">30 Days</option>
                <option value="60">60 Days</option>
                <option value="90">90 Days</option>
                <option value="120">120 Days</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Package Pricing
              </label>
              {/*  <input
                type="number"
                id="address"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter package pricing"
                required
              /> */}
              <div id="address">
                {vehicleTypes.map((type) => (
                  <div key={type.name} className="mb-2">
                    <label
                      htmlFor={type.name}
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >{type.name} </label>
                    <input
                      type="number"
                      id={type.name}
                      name={type.name}
                      placeholder={`Enter pricing for ${type.name}`}
                      value={
                        formData.pricings.find((pricing) => pricing.vehicleTypeId === type._id)?.amount || ""
                      }
                      onChange={(e) => handlePriceChange(type._id, e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                ))
                }
              </div>

            </div>

            <div className="mb-5">
              <label
                htmlFor="latitude"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                No Of Quick Wash
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={formData.quickWash}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, quickWash: e.target.value }))}
              >
                <option select>Select number of washes</option>
                {Array.from({ length: 20 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="customer-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                No Of Premium Washes
              </label>
              <select
                id="customer-type"
                value={formData.premiumWash}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(e) => setFormData((prevData) => ({ ...prevData, premiumWash: e.target.value }))}
              >
                <option select>Select number of washes</option>
                {Array.from({ length: 20 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="mb-5">
              <label
                htmlFor="reference-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Status
              </label>
              <select
                id="reference-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Active / Inactive</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div> */}
          </div>


        </div>

        <div className="flex justify-left mt-6">
          <button
            type="submit"
            className="flex justify-left py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </div>
      </MainSection >
    </div >
  );
}

export default Editpackage;
