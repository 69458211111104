import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete  } from "react-icons/ai"; // Import the eye icon from react-icons
import { get_all_enquiries } from "../../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";

const EnquiryManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddVendor = () => {
    const addVendorPath = "/Addenquiry";
    navigate(addVendorPath);
  };

  const token = localStorage.getItem("token");
  const all_enquiry = useSelector((state) => state.auth.all_enquiry);

  console.log(all_enquiry, "all_enquiry");

  useEffect(() => {
    if (token) {
      dispatch(get_all_enquiries(token)); // Fetch the enquiries when the token is available      
    }
  }, [token, dispatch]);

  useEffect(() => {
    // Initialize DataTable when data is available
    if (all_enquiry.length > 0) {
      $("#vendorTable").DataTable();
    }
  }, [all_enquiry]);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="All Enquiries" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddVendor}
          >
            Create New Enquiry
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Table structure matching the provided image */}
        <DatatableSection>
          <div className="overflow-x-auto">
            <table id="vendorTable" className="display">
              <thead>
                <tr>
                  <th>Enquiry ID</th>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Mobile No</th>
                  <th>Address</th>
                  <th>Vehicle Name</th>
                  <th>Vehicle No</th>
                  {/* <th>Customer Type</th> */}
                  <th>Desired Time & Date</th>
                  <th>Status</th>
                  <th>Action</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {all_enquiry.length > 0 ? (
                  all_enquiry.map((enquiry, index) => (
                    <tr key={index}>
                      <td>{enquiry.id}</td>
                      <td>{new Date(enquiry.date).toLocaleDateString()}</td>
                      <td>{enquiry.customerName}</td>
                      <td>{enquiry.phoneNumber}</td>
                      <td>{enquiry.address}</td>
                      <td>
                        {enquiry.vehicleName?.maker ||
                          enquiry.vehicleName}
                      </td>
                      <td>{enquiry.vehicleNumber}</td>
                      {/* <td>
                        <select className="border border-gray-300 rounded px-2 py-1">
                          <option
                            value="New"
                            selected={enquiry.customerType === "New"}
                          >
                            New
                          </option>
                          <option
                            value="Existing"
                            selected={enquiry.customerType === "Existing"}
                          >
                            Existing
                          </option>
                        </select>
                      </td> */}
                      <td>
                        {new Date(enquiry.desiredDate).toLocaleString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                          day: "numeric",
                          month: "short",
                          year: "numeric"
                        })}
                      </td>
                      <td>
                        {enquiry.status === true ? "In Queue" : "Converted"}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                      <button
                          className="bg-white text-black border border-black px-2 py-2 rounded hover:bg-red-800"
                          
                        >
                          <AiOutlineDelete />
                        </button>
                      </td>

                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No enquiries found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default EnquiryManagement;
