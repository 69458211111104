import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai"; // Import the edit icon from react-icons
import { getAllWashboy } from "../../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { updateWashboyStatus } from "../../redux/actions/authActions"

// Sample data to populate the table

const WashBoyManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddWashboy = () => {
    const addVendorPath = "/Wash_Boy_Management/AddWashBoy";
    navigate(addVendorPath);
  };
  const token = localStorage.getItem("token");
  const wash_boy = useSelector((state) => state.auth.get_wash_boy);
  const loading = useSelector((state) => state.auth.loading);

  const EditWashboy = (id) => {
    navigate(`/EditWashboy/${id}`);
  };

  console.log(wash_boy);


  const handleToggleStatus = (washboyId, currentStatus) => {
    // Optimistically update the UI by toggling the status
    const newStatus = !currentStatus;
    // Dispatch action to update status in the database
    dispatch(updateWashboyStatus(washboyId, newStatus));
  };

  useEffect(() => {
    if (token) {
      dispatch(getAllWashboy(token)); // Fetch the services when the token is available
    }
  }, [token, dispatch, loading]);

  // useEffect(() => {
  //   // Initialize DataTable after the component has mounted
  //   $("#washBoyTable").DataTable({
  //     paging: true,
  //     searching: true,
  //     ordering: true,
  //     pageLength: 10,
  //     lengthMenu: [10, 20, 30, 50],
  //   });

  //   // Cleanup function to destroy the DataTable when the component unmounts
  //   return () => {
  //     $("#washBoyTable").DataTable().destroy();
  //   };
  // }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Wash Boy Management" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddWashboy}
          >
            Create Wash Boy
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Table structure matching the provided image */}
        <DatatableSection>
          <div className="overflow-x-auto">
            <table
              id="washBoyTable"
              className="display w-full text-left border-collapse"
              style={{ borderSpacing: "0 10px" }}
            >
              <thead className="bg-gray-100">
                <tr>
                  <th className="border-b-2 border-gray-300 p-3">
                    Wash Boy ID
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    {" "}
                    Creation Date
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Wash Boy Name
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Phone Number
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Email Address
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    All Bookings
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Bookings Assigned
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Status{" "}
                  </th>
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Action{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {wash_boy?.map((item, index) => (
                  <tr key={index} className="bg-white hover:bg-gray-100">
                    <td className="p-3 border-b border-gray-200">{item?.id}</td>
                    <td className="p-3 border-b border-gray-200">
                      {item?.createdAt}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {item?.name}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {item?.phoneNumber}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {item?.email}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {item?.activeCount}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {item.bookingsAssigned || 0}
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      {/* Toggle Switch */}
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={item.status}
                          className="sr-only peer"
                          onClick={() => handleToggleStatus(item._id, item.status)}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-black"></div>
                        <span className="absolute inset-y-0 left-0 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-5"></span>
                      </label>
                    </td>
                    <td className="p-3 border-b border-gray-200">
                      <button
                        className="bg-black text-white px-2 py-1 rounded flex items-center"
                        onClick={() => EditWashboy(item._id)}
                      >
                        <AiOutlineEdit className="mr-1" /> {/* Edit icon */}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default WashBoyManagement;
