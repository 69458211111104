import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai"; // Import the edit icon from react-icons
import { useSelector, useDispatch } from "react-redux";
import { getAllPackages, updatePackageStatus } from "../../redux/actions/authActions";

//Sample data to populate the table
/* const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    packageId: `COC${i}023`,
    packageName: "Package Name",
    packageDuration: ["01 Month", "02 Months", "03 Months"][i % 3],
    packagePricing: [500, 1000][i % 2],
    premiumWashes: `0${i % 2 + 1}`,
    quickWashes: `0${i % 2 + 1}`,
    status: i % 2 === 0, // Simulate toggle status
  });
} */

const PackageManagement = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allPackages = useSelector((state) => state.auth.allPackages);
  const loading = useSelector((state) => state.auth.loading);
  console.log(allPackages[0]);

  const handleAddPackage = () => {
    const addVendorPath = "/Package_Management/Addpackage";
    navigate(addVendorPath);
  };

  const EditPackage = (id) => {
    navigate(`/EditPackage/${id}`);
  };


  const handleToggleStatus = (packageId, currentStatus) => {
    // Optimistically update the UI by toggling the status
    const newStatus = !currentStatus;
    // Dispatch action to update status in the database
    dispatch(updatePackageStatus(packageId, newStatus));
  };

  useEffect(() => {
    dispatch(getAllPackages(token));
  }, [dispatch, token, loading]
  )



  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Package Management" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddPackage}
          >
            Create Package
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Table structure matching the provided image */}
        <DatatableSection>
          <div className="overflow-x-auto">
            {allPackages && allPackages.length > 0 ? (
              <table id="packageTable" className="display w-full text-left border-collapse"
                style={{ borderSpacing: "0 10px" }}>
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border-b-2 border-gray-300 p-3">Package ID</th>
                    <th className="border-b-2 border-gray-300 p-3">Package Name</th>
                    <th className="border-b-2 border-gray-300 p-3">Package Duration</th>
                    <th className="border-b-2 border-gray-300 p-3">Package Pricing</th>
                    <th className="border-b-2 border-gray-300 p-3">Premium Washes</th>
                    <th className="border-b-2 border-gray-300 p-3">Quick Washes</th>
                    <th className="border-b-2 border-gray-300 p-3">Status</th>
                    <th className="border-b-2 border-gray-300 p-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allPackages.map((item, index) => (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="p-3 border-b border-gray-200">{index + 1}</td>
                      <td className="p-3 border-b border-gray-200">{item.name}</td>
                      <td className="p-3 border-b border-gray-200">{item.duration}</td>
                      <td className="p-3 border-b border-gray-200">{item.name}</td>
                      <td className="p-3 border-b border-gray-200">{item.premiumWash}</td>
                      <td className="p-3 border-b border-gray-200">{item.quickWash}</td>
                      <td className="p-3 border-b border-gray-200">
                        {/* Toggle Switch */}
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={item.status}
                            className="sr-only peer"
                            onChange={() => { handleToggleStatus(item._id, item.status) }}
                          />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-black"></div>
                          <span className="absolute inset-y-0 left-0 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-5"></span>
                        </label>
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        <button className="bg-black text-white px-2 py-1 rounded flex items-center" onClick={(e) => EditPackage(item._id)}>
                          <AiOutlineEdit className="mr-1" /> {/* Edit icon */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No packages available</div>
            )}
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default PackageManagement;
