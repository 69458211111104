import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { getActiveEnqCustomers, getAvailableWashboys, addBooking } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function ViewBooking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const activeCustomers = useSelector((state) => state.auth.activeCustomers);
  const availableWashboys = useSelector((state) => state.auth.availableWashboys);
  // const customerEnquiries = useSelector((state) => state.auth.customerEnquiries);
  const [customerEnquiries, setCustomerEnquiries] = useState([]);
  console.log(activeCustomers, "Active Customers");
  /*   const activeCustomervehicles = useSelector((state) => state.auth.activeCustomerVehicles);*/

  const [formData, setFormData] = useState({
    customerNumber: "",
    vehicleName: "",
    vehicleNumber: "",
    address: "",
    time: "",
    serviceName: ""
  })
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState("");
  const [time, setTime] = useState("");
  const [washboy, setWashboy] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(getActiveEnqCustomers(token)); // Fetch the enquiries when the token is available
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (formData.customerNumber && activeCustomers) {
      const selected = activeCustomers.find((customer) => customer.phoneNumber === formData.customerNumber);
      setSelectedCustomer(selected);
      setFormData((prevData) => ({
        ...prevData,
        vehicleName: "",
        vehicleNumber: "",
        address: "",
        serviceName: "",
      }));
      setSelectedEnquiry(null);
    }
  }, [formData.customerNumber, activeCustomers])

  useEffect(() => {
    if (formData.vehicleName) {
      const enquiry = customerEnquiries.find(enquiry =>
        enquiry.vehicleName.model === formData.vehicleName)
      setSelectedEnquiry(enquiry);
      const selectedTime = enquiry.desiredDate;
      setTime(selectedTime);

    }
  }, [formData.vehicleName, customerEnquiries])

  useEffect(() => {
    if (selectedCustomer) {
      //dispatch(getCustomerEnquiries(token, selectedCustomer.phoneNumber));
      const enquiries = activeCustomers.filter((enquiry) => enquiry.phoneNumber === selectedCustomer.phoneNumber)
      setCustomerEnquiries(enquiries);
    }
  }, [selectedCustomer, dispatch, token])

  useEffect(() => {
    if (selectedEnquiry) {
      setFormData((prevData) => ({
        ...prevData,
        vehicleNumber: selectedEnquiry.vehicleNumber,
        address: selectedEnquiry.address,
        serviceName: selectedEnquiry.serviceType.name,
        time: (selectedEnquiry.desiredDate),
      }))
    }
  }, [selectedEnquiry])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    if (token && time) {
      console.log(time);
      dispatch(getAvailableWashboys(time, selectedEnquiry.serviceType.time));
    }
  }, [token, time, dispatch]);

  const handleWashboyChange = (e) => {
    const id = e.target.value;
    setWashboy(availableWashboys.find((washboy) => washboy.washboyId === id));

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBooking(formData, selectedEnquiry._id, washboy));
    setTimeout(3000, navigate("/BookingManagement"));
  }


  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="View On Demand Booking" />
        <hr className="my-4" />

        {/* Left-aligned container for the form with 50% width */}
        <div className="w-1/2 mt-5">
          {/* Form Fields Section */}
          <div className="container text-start">
            {/* Customer Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="customerNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Number
              </label>
              <select
                id="customerNumber"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                value={formData.customerNumber}
                onChange={handleInputChange}
                //onBlur={handleCustomerBlur}
                required
              >
                <option value="" disabled select>
                  Select customer number
                </option>
                {activeCustomers && activeCustomers.map((customer) => (
                  <option value={customer.phoneNumber} >{customer.phoneNumber}</option>
                ))};
              </select>
            </div>

            {/* Vehicle Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="vehicleName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Name
              </label>
              <select
                id="vehicleName"
                value={formData.vehicleName}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled select>
                  Select vehicle
                </option>
                {
                  customerEnquiries.map((enquiry) =>
                    <option value={enquiry?.vehicleName?.model}>{enquiry?.vehicleName?.model}</option>
                  )
                } || 'Select a customer first'
              </select>
            </div>

            {/* Vehicle Number Input */}
            <div className="mb-4">
              <label
                htmlFor="vehicleNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Number
              </label>
              <input
                type="text"
                id="vehicleNumber"
                value={formData.vehicleNumber}
                onChange={handleInputChange}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Vehicle number"
                required
              />
            </div>

            {/* Address Input */}
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                value={formData.address}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Address comes here"
                required
              />
            </div>



            {/* Service Name Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="serviceName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Service Name
              </label>
              <select
                id="serviceName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
              >
                <option value="" disabled selected>
                  Select service
                </option>
                <option value={formData.serviceName}>{formData.serviceName}</option>

              </select>
            </div>


            {/* Time Dropdown */}
            <div className="mb-4">
              <label
                htmlFor="time"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Time
              </label>
              <input
                type="text"
                id="time"
                value={new Date(formData.time).toLocaleString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                placeholder="Time comes here"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="time"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Wash Boy
              </label>
              <select
                id="washboy"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                required
                value={washboy.washboyId}
                onChange={handleWashboyChange}
              >
                <option value="" disabled selected>
                  Select washboy
                </option>
                {availableWashboys.map((washboy) => (
                  <option value={washboy.washboyId} >{washboy.name} Closest-time: {new Date(washboy.closestAvailableStartTime).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true
                  })}</option>
                ))};
              </select>
            </div>

            {/* Submit Button */}
            {/* <div className="flex justify-start mt-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="py-3 px-6 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </MainSection>
    </div>
  );
}

export default ViewBooking;
