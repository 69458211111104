import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getAllVehicles, addUser, getOneUser } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function ViewUser() {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	const token = localStorage.getItem("token");
	const oneUser = useSelector((state) => state.auth.oneUser);
	useEffect(() => {
		if (token) {
			dispatch(getOneUser(token, id));
		}
	}, [token, id])

	const handleSubmit = async (e) => {
		e.preventDefault();
		navigate("/AllUserManagement")
	}


	return (
		<div>
			<Sidebar />

			<MainSection>
				<SectionHeader title={`${oneUser?.name} ${oneUser?.id}`} />

				{/* Form for creating user */}
				<form onSubmit={handleSubmit}>
					<div className="grid grid-cols-1 md:grid-cols-[30%_68%] gap-8">
						{/* Left Column */}
						<div className="container text-start">
							<div className="mb-5">
								<label
									htmlFor="name"
									className="block mb-2 text-xl mt-2 font-medium text-gray-900"
								>
									Customer Details
								</label>
								<label
									htmlFor="name"
									className="block mb-2 text-sm font-medium text-gray-900"
								>
									Customer Name
								</label>
								<input
									type="text"
									id="name"
									name="name"
									value={oneUser?.name}
									readOnly
									className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
									placeholder="Enter customer name"
									required
								/>
							</div>

							<div className="mb-5">
								<label
									htmlFor="phoneNumber"
									className="block mb-2 text-sm font-medium text-gray-900"
								>
									Phone Number
								</label>
								<input
									type="text"
									id="phoneNumber"
									name="phoneNumber"
									readOnly
									value={oneUser?.phoneNumber}
									className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
									placeholder="Enter phone number"
									required
								/>
							</div>

							<div className="mb-5">
								<label
									htmlFor="AlternateNumber"
									className="block mb-2 text-sm font-medium text-gray-900"
								>
									Alternate Phone Number (Optional)
								</label>
								<input
									type="text"
									id="AlternateNumber"
									readOnly
									name="AlternateNumber"
									value={oneUser?.AlternateNumber}
									className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
									placeholder="Enter alternate phone number"
								/>
							</div>

							{/* <div className="mb-5">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  User Address
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleFormChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter user address"
                  required
                />
              </div> */}
						</div>

						{/* Right Column - Vehicle Details Section */}
						<div className="container text-start">
							<label className="block text-xl mt-2 font-medium mb-2">Vehicle Details</label>
							<div>
								{oneUser?.vehicles?.map((vehicle, index) => (
									<div key={index} className="flex items-center gap-2">
										{/* Vehicle Dropdown */}
										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Vehicle Maker & Model
											</label>
											<input
												type="text"
												readOnly
												value={`${vehicle?.vehicleId?.maker}   ${vehicle?.vehicleId?.model}`}
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
												placeholder="Enter phone number"
												required
											/>
										</div>

										{/* Vehicle Color */}
										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Vehicle Color
											</label>
											<input
												type="text"
												readOnly
												value={vehicle?.vehicleId?.vehicleColors.join()}
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
												placeholder="Enter vehicle color"
											/>
										</div>

										{/* Vehicle Number */}
										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Vehicle Number
											</label>
											<input
												type="text"
												value={vehicle?.vehicleNumber}
												readOnly
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
												placeholder="Enter vehicle number"
											/>
										</div>
									</div>
								))}

							</div>

							<label className="block text-xl mt-2 font-medium mb-2">Address Details</label>
							<div>
								{oneUser?.addresses?.map((address, index) => (
									<div key={index} className="flex items-center gap-2">
										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Address
											</label>
											<input
												type="text"
												value={address?.address}
												readOnly
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
												placeholder="Enter Address"
											/>
										</div>

										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Latitude
											</label>
											<input
												type="text"
												value={address.latitude}
												readOnly
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
												placeholder="Enter Latitude"
											/>
										</div>

										<div className="mb-2 w-full">
											<label className="block mb-1 text-sm font-medium text-gray-900">
												Longitude
											</label>
											<input
												type="text"
												readOnly
												value={address.longitude}
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
												placeholder="Enter Longitude"
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="flex justify-left mt-6">
						<button
							type="submit"
							className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							Back
						</button>
					</div>
				</form>
			</MainSection>
		</div>
	);
}

export default ViewUser;
