import "./Button.css"


const ReusableButton = ({ label, className, onClick, htmlType = 'button' }) => {
  return (
    <button
      type={htmlType}
      className={`btn-reusable ${className} px-4 py-2`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ReusableButton;
