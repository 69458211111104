import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateVehicleType() {
  const navigate = useNavigate();
  const [vehicleType, setVehicleType] = useState(null);

  async function createVehicle() {
    if (vehicleType == null) {
      alert('Please entire the vehicle type')
    }
    else {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        // Assuming the token is stored in localStorage after login
        const token = localStorage.getItem('token'); // or sessionStorage if you're using that

        // Prepare the headers with the Authorization Bearer token
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`, // Bearer token included
            'Content-Type': 'application/json', // Add any other headers if necessary
          },
        };

        // API request with vehicleType and token in the header
        const response = await axios.post(`${apiUrl}/api/createVehicleType`, { vehicleType }, config);

        console.log(response);
        // alert(JSON.stringify(response));
        if (response.status === 200) {

          toast.success(response.data, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          navigate('/VehicleTypeManagement')
        }
      } catch (error) {
        // console.error('Error creating vehicle:', error.status);
        if (error.status === 400) {
          alert('Vehicle type already added')
        }
        else {
          alert('Something went wrong while creating vehicle type')
        }

      }
    }
  }

  return (
    <div>
      <Sidebar />
      <MainSection>
        <SectionHeader title="Create New Type" />

        {/* Form Section */}
        <div className="container mx-auto text-start mt-6">
          <div className="w-full md:w-1/2 md:ml-0">

            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Enter Vehicle Type Name
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Enter vehicle type"
                onChange={(e) => setVehicleType(e.target.value)}
              />
            </div>


            {/* Submit Button */}
            <div className="mt-5">
              <button
                type="submit"
                className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-blue-700"
                onClick={createVehicle}
              >
                Create Type
              </button>
            </div>

          </div> {/* End of responsive width container */}
        </div>
      </MainSection>
    </div>
  );
}

export default CreateVehicleType;
