import axios from "axios";
import { toast } from "react-toastify";

// Login Action (async using redux-thunk)
export const login = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOGIN_REQUEST" });

      const apiUrl = process.env.REACT_APP_API_URL;
      console.log(apiUrl, "api url");

      const response = await axios.post(`${apiUrl}/api/login`, {
        email,
        password,
      });

      console.log(response);

      if (response.status === 200) {
        localStorage.setItem("token", response.data.accessToken);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: response.data,
        });

        toast.success(`${response?.data?.message}`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });

        return Promise.resolve();  // Resolve on success
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      console.log(error);
      const errMsg = error.response?.data?.message || "An error occurred";
      dispatch({ type: "LOGIN_FAILURE" });  // Dispatch failure action

      toast.error(`${errMsg}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      return Promise.reject(error);  // Reject on failure
    }
  };
};


// Login Action (async using redux-thunk)
export const addVehicle = (values) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addVehicle`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_VEHICLE_SUCCESS",
        payload: response.data,
      });

      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      var errMsg = error.response.data.message || error.response.data;
      alert(JSON.stringify(errMsg));
    }
  };
};

// Get Vehicle Types Action (async using redux-thunk)
export const getVehicleType = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/vehicleTypes`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_VEHICLE_TYPE",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};

export const getAllVehicles = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/allVehicles`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_VEHICLES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};



export const addEnquiry = (values) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addEnquiry`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_ENQUIRY_SUCCESS",
        payload: response.data,
      });

      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      var errMsg = error.response.data.message || error.response.data;
    }
  };
};

export const getActiveServices = (token, id) => {
  return async (dispatch) => {
    try {
      console.log(id, "id");
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(
        `${apiUrl}/api/activeServices`,
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ACTIVE_SERVICES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      alert(JSON.stringify(errMsg));
    }
  };
};

export const get_all_enquiries = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api//enquiries`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_ENQUIRY",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const addService = (values) => {
  return async (dispatch) => {
    console.log(values, "vaalues");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addService`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_SERVICES",
        payload: response.data,
      });

      toast.success(`${response?.data?.message || response?.data}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      toast.error(
        `${error?.response?.data?.message || error?.response?.message}`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      console.log(error);
      var errMsg = error.response.data.message || error.response.data;
    }
  };
};

export const get_all_services = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/allServices`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_SERVICES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      alert(JSON.stringify(errMsg));
    }
  };
};

export const updateServiceStatus = (id, status) => {
  return async (dispatch) => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      // Include the token in the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(
        `${apiUrl}/api/updateServiceStatus/${id}`,
        { status },
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_SERVICE_STATUS",
        payload: response.data
      });
      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
}

export const addWashBoy = (values) => {
  return async (dispatch) => {
    console.log(values, "vaalues");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addWashboy`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_WASH_BOY",
        payload: response.data,
      });

      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message}`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};

export const addUser = (values) => {
  return async (dispatch) => {
    console.log(values, "vaalues");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addUser`,
        values,
        config
      );

      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_USER",
        payload: response.data,
      });
      console.log(response.data, "response data");
      toast.success(`${response.data.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message}`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};

export const getAllUsers = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/users`, config);

      console.log(response.data, "data");
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_USERS",
        payload: response?.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};

export const getAllWashboy = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/washboys`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_WASH_BOY",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};

export const getPhoneUser = (values) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await axios.post(
        `${apiUrl}/api/users/phone_number`,
        values,
        config
      );

      // alert(JSON.stringify(response.status))
      dispatch({
        type: "USER_PHONE",
        payload: response.data,
      });
      console.log(response.data, "response data");

      return response;


      // if (response.data.message == "User not found") {
      //   setModalMessage(response.data.message);
      //   setShowModal(true);
      // }

      // toast.success(`${response.data.message}`, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeButton: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "light",
      // });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      return {
        status: error.response?.status,
        message: error.response?.data?.message || "An error occurred",
      };
      // toast.error(
      //   `${error?.response?.data?.message || error?.response?.message}`,
      //   {
      //     position: "top-center",
      //     autoClose: 1000,
      //     hideProgressBar: true,
      //     closeButton: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     theme: "light",
      //   }
      // );
    }
  };
};

export const deleteVehicle = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await axios.delete(
        `${apiUrl}/api/deleteVehicle/${id}`,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "DELETE_VEHICLE_SUCCESS",
        payload: response.data,
      });

      dispatch(getAllVehicles(token));

      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      var errMsg = error.response.data.message || error.response.data;
    }
  };
};

export const get_one_vehicle = (id, token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(
        `${apiUrl}/api/one_vehicle/${id}`,
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_VEHICLE",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};

export const editVehicle = (id, data) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      // Include the token in the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(
        `${apiUrl}/api/editVehicle/${id}`,
        data,
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_VEHICLE",
        payload: response.data,
      });
      toast.success(`${response?.data}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  };
};


export const getAllBookings = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/allBookings`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_BOOKINGS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const addBooking = (values, id, wb) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/createBooking/${id}`,
        {
          wb,
          values
        },
        config,
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_BOOKING",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};


export const getActiveEnqCustomers = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${apiUrl}/api/activeEnqCustomers`, config);
      console.log(response);
      dispatch({
        type: "GET_ACTIVE_ENQUIRIES_CUSTOMERS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const getAvailableWashboys = (time, serviceTime) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const token = localStorage.getItem("token");
      const desiredTime = time;
      console.log(desiredTime);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(`${apiUrl}/api/availableWashboys`, { desiredTime, serviceTime }, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_AVAILABLE_WASHBOYS",
        payload: response.data.availableWashboys,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const addPackage = (values, id) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addPackage`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_PACKAGE",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};

export const getAllPackages = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/allPackages`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_PACKAGES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const getOnePackage = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/packages/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ONE_PACKAGE",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};

export const editPackage = (values, id) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/editPackage/${id}`,
        values,
        config
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "EDIT_PACKAGE",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};

export const getCustomerEnquiries = (token, phno) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/activeCustomerEnquiries/${phno}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_CUSTOMER_ENQUIRIES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};


export const addPackageUser = (token, values) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/addPackageUser`,
        values,
        config,
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_PACKAGEUSER",
        payload: values,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
}



export const getPackageUsers = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/packageUsers`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_PACKAGEUSERS",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}

export const getOnePackageUser = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/packageUser/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ONE_PACKAGEUSER",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}


export const getActivePackageUsers = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/activePackageUsers`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ACTIVE_PACKAGEUSERS",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}


export const getPackageUserByNumber = (token, no) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/packageUserByNumber/${no}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_PACKAGEUSER_BY_NUMBER",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}

export const addPackageBooking = (values, userId, wb) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/createPackageBooking/${userId}`,
        {
          wb,
          values
        },
        config,
      );

      console.log(response, "response");
      // alert(JSON.stringify(response.status))
      dispatch({
        type: "ADD_PACKAGE_BOOKING",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};



export const getAllPackageBookings = (token) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/allPackageBookings`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ALL_PACKAGE_BOOKINGS",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  };
};




export const deleteUser = (token, id) => {

  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.delete(`${apiUrl}/api/deleteUser/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "DELETE_USER",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });


    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}


export const getOneService = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/oneService/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ONE_SERVICE",
        payload: response.data,
      });


    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}

export const editService = (token, values, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(`${apiUrl}/api/editService/${id}`, values, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_SERVICE",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}


export const deleteService = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.delete(`${apiUrl}/api/deleteService/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "DELETE_SERVICE",
        payload: response.data,
      });

      toast.success(`${response?.data} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
}

export const getOneWashboy = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/washboys/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ONE_WASHBOY",
        payload: response.data,
      });

    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
};


export const editWashboy = (token, values, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(`${apiUrl}/api/editWashboy/${id}`, values, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_SERVICE",
        payload: response.data,
      });

      toast.success(`${response?.data?.message} `, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message} `,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
};


export const updateWashboyStatus = (id, status) => {
  return async (dispatch) => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      // Include the token in the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(
        `${apiUrl}/api/toggleWashboy/${id}`,
        { status },
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_WASHBOY_STATUS",
        payload: response.data
      });
      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  }
}

export const updatePackageStatus = (id, status) => {
  return async (dispatch) => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      // Include the token in the Authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.post(
        `${apiUrl}/api/togglePackage/${id}`,
        { status },
        config
      );
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "EDIT_PACKAGE_STATUS",
        payload: response.data
      });
      toast.success(`${response?.data?.message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);

      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
      // alert(JSON.stringify(errMsg));
    }
  }
}

export const getOneUser = (token, id) => {
  return async (dispatch) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Include the token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Make the GET request to fetch vehicle types
      const response = await axios.get(`${apiUrl}/api/users/${id}`, config);
      console.log(response);
      // Dispatch action to update the vehicle types in the Redux store
      dispatch({
        type: "GET_ONE_USER",
        payload: response.data,
      });

    } catch (error) {
      console.log(error);
      // Gracefully handle the error and show a message if available
      const errMsg =
        error.response?.data?.message || error.message || "An error occurred";
    }
  }
};


export const editUser = (values, id) => {
  return async (dispatch) => {
    console.log(values, "values");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${apiUrl}/api/editUser/${id}`,
        values,
        config
      );

      // alert(JSON.stringify(response.status))
      dispatch({
        type: "EDIT_USER",
        payload: response.data,
      });
      console.log(response.data, "response data");
      toast.success(`${response.data}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      // dispatch(getAllVehicles(token));
    } catch (error) {
      console.log(error);
      toast.error(
        `${error?.response?.data?.message || error?.response?.message}`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };
};