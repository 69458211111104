import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify"

function EditVehicleType() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicleType, setVehicleType] = useState("");

  // Fetch existing vehicle type data on component mount
  useEffect(() => {
    async function fetchVehicleType() {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.get(`${apiUrl}/api/vehicleTypes/${id}`, config);

        if (response.status === 200) {
          setVehicleType(response.data.name); // Assuming vehicle type name is in response
        }
      } catch (error) {
        console.error("Error fetching vehicle type data:", error);
        alert("Could not fetch vehicle type data.");
      }
    }

    fetchVehicleType();
  }, [id]);

  async function handleClick() {
    if (!vehicleType) {
      alert("Please enter the vehicle type");
    } else {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.post(
          `${apiUrl}/api/editVehicleType/${id}`,
          { name: vehicleType },
          config
        );

        if (response.status === 200) {

          toast.success(`Vehicle Type edited successfully`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          navigate("/VehicleTypeManagement");
        }
      } catch (error) {
        console.error("Error updating vehicle type:", error);
        if (error.response && error.response.status === 400) {
          alert("Vehicle type already exists.");
        } else {
          alert("Something went wrong while updating vehicle type.");
        }
      }
    }
  }

  return (
    <div>
      <Sidebar />
      <MainSection>
        <SectionHeader title="Edit Vehicle Type" />

        {/* Form Section */}
        <div className="container mx-auto text-start mt-6">
          <div className="w-full md:w-1/2 md:ml-0">
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Enter Vehicle Type Name
              </label>
              <input
                type="text"
                value={vehicleType} // Controlled input
                onChange={(e) => setVehicleType(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Enter name"
              />
            </div>

            {/* Submit Button */}
            <div className="mt-5">
              <button
                type="submit"
                onClick={handleClick}
                className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </MainSection>
    </div>
  );
}

export default EditVehicleType;
