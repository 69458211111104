import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Using NavLink for active class
import Logo from "../../assets/logo.png";
import { CiLogout } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();


  const logout = async () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [activeLink, setActiveLink] = useState("/dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar visibility state
  const [showBookingsDropdown, setShowBookingsDropdown] = useState(false); // Bookings dropdown state

  const navItems = [
    // { name: "Dashboard", path: "/dashboard" },
    { name: "All Users", path: "/AllUserManagement" },
    { name: "Wash Boy Management", path: "/WashBoyManagement" },
    { name: "Package Management", path: "/PackageManagement" },
    { name: "Service Management", path: "/ServiceMangement" },
    { name: "Package Users", path: "/PackageUserManagement" },
    // { name: "Payment Management", path: "/Payment_Management" },
    // { name: "Reports", path: "/Reports" },
    { name: "Vehicle Management", path: "/VehicleManagement" },
    { name: "Vehicle Type", path: "/VehicleTypeManagement" },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-[calc(100%-20px)] mx-2.5 mt-0 bg-black border-b border-gray-200 rounded-lg">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center w-full">
              <button
                onClick={toggleSidebar} // Toggle sidebar when button is clicked
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-white rounded-lg sm:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <span className="text-xl font-semibold text-white sm:text-2xl">
                Clean Our Car
              </span>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-24 transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } bg-white border-r border-gray-200 sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
          <ul className="space-y-1 font-semibold">
            {/* Enquires item */}

            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `flex justify-start items-center p-1 rounded-lg group ${isActive
                    ? "bg-orange-100 text-black-500 border-l-4 border-blue-500"
                    : "text-gray-900 hover:bg-gray-100"
                  }`
                }
                onClick={() => setActiveLink("/dashboard")}
              >
                <span className="ms-3">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Enquiry"
                className={({ isActive }) =>
                  `flex justify-start items-center p-1 rounded-lg group ${isActive
                    ? "bg-orange-100 text-black-500 border-l-4 border-blue-500"
                    : "text-gray-900 hover:bg-gray-100"
                  }`
                }
                onClick={() => setActiveLink("/Enquiry")}
              >
                <span className="ms-3">Enquires</span>
              </NavLink>
            </li>

            {/* Bookings Dropdown - Placed directly after Enquires */}
            <li>
              <button
                onClick={() => setShowBookingsDropdown(!showBookingsDropdown)}
                className="flex items-center w-full p-2 text-gray-900 rounded-lg hover:bg-gray-100"
              >
                <span className="ms-3">Bookings</span>
                <svg
                  className={`w-4 h-4 ml-auto transition-transform ${showBookingsDropdown ? "rotate-180" : ""
                    }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {showBookingsDropdown && (
                <ul className="pl-8 mt-1 space-y-1">
                  <li>
                    <NavLink
                      to="/BookingManagement"
                      className={({ isActive }) =>
                        `flex justify-start items-center p-1 rounded-lg group ${isActive
                          ? "bg-orange-100 text-black-500 border-l-4 border-blue-500"
                          : "text-gray-900 hover:bg-gray-100"
                        }`
                      }
                      onClick={() =>
                        setActiveLink("/BookingManagement")
                      }
                    >
                      On Demand Booking
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/PackageBookingManagement"
                      className={({ isActive }) =>
                        `flex justify-start items-center p-1 rounded-lg group ${isActive
                          ? "bg-orange-100 text-black-500 border-l-4 border-blue-500"
                          : "text-gray-900 hover:bg-gray-100"
                        }`
                      }
                      onClick={() =>
                        setActiveLink("/PackageBookingManagement")
                      }
                    >
                      Package Booking
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            {/* Render other navigation items */}
            {navItems.map((item) => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex justify-start items-center p-1 rounded-lg group ${isActive
                      ? "bg-orange-100 text-black-500 border-l-4 border-blue-500"
                      : "text-gray-900 hover:bg-gray-100"
                    }`
                  }
                  onClick={() => setActiveLink(item.path)}
                >
                  <span className="ms-3">{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>

          {/* Logout Button */}
          <div className="fixed bottom-0 left-0 w-full justify-center  bg-white">
            <button
              type="button"
              className="text-black font-semibold inline-flex items-center justify-between border-2 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={logout}
            >
              <span className="mr-6">
                <CiLogout />
              </span>
              Logout
            </button>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
