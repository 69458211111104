import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai"; // Import the eye icon from react-icons
import { AiOutlineEdit } from "react-icons/ai"; // Import the edit icon from react-icons
import { getAllUsers } from "../../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";

const AllUserManagement = () => {
  const navigate = useNavigate();

  const CreateUser = () => {
    const addVendorPath = "/CreateUser";
    navigate(addVendorPath);
  };

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  console.log(user, "users");

  useEffect(() => {
    if (token) {
      dispatch(getAllUsers(token)); // Fetch the users when the token is available
    }
  }, [token, dispatch]);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="All Users" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={CreateUser}
          >
            Create New User
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        <DatatableSection className="w-full overflow-x-auto">
          <div className="overflow-x-auto">
            <table
              id="vehicleTable"
              className="display w-full text-left border-collapse"
              style={{ borderSpacing: "0 10px" }} // Adding row spacing
            >
              <thead className="bg-gray-100">
                <tr>
                  <th className="border-b-2 border-gray-300 p-3">User ID</th>
                  <th className="border-b-2 border-gray-300 p-3"> Date</th>
                  <th className="border-b-2 border-gray-300 p-3">
                    Customer Name
                  </th>
                  <th className="border-b-2 border-gray-300 p-3">Mobile No</th>
                  {/* <th className="border-b-2 border-gray-300 p-3 text-center">
                    Address
                  </th> */}
                  <th className="border-b-2 border-gray-300 p-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {user && user?.length > 0 ? (
                  user?.map((item, index) => (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="p-3 border-b border-gray-200">
                        {item.id}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {new Date(item?.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item?.name}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item?.phoneNumber}
                      </td>
                      {/* <td className="p-3 border-b border-gray-200">
                        {item?.addresses[0].address}
                      </td> */}
                      <td className="p-3 border-b border-gray-200">
                        <div className="flex space-x-2">
                          <button className="bg-black text-white px-3 py-1 rounded flex items-center"
                            onClick={() => navigate(`/ViewUser/${item._id}`)}>
                            View <AiOutlineEye className="ml-2" />
                          </button>
                          <button
                            className="bg-black text-white px-3 py-1 rounded flex items-center"
                            onClick={() => navigate(`/EditUser/${item._id}`)}
                          >
                            <AiOutlineEdit className="mr-2" /> {/* Edit icon */}
                            Edit
                          </button>
                        </div>
                      </td>


                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center p-3">
                      <p>Loading...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default AllUserManagement;
