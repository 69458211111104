import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getAllVehicles, addUser } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CreateUser() {
  const [vehicles, setVehicles] = useState([
    { vehicleName: "", vehicleColor: "", vehicleNumber: "" },
  ]);
  const [addresses, setAddresses] = useState([{ address: "", latitude: "", longitude: "" }]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const all_vehicles = useSelector((state) => state.auth.all_vehicles);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    AlternateNumber: "",
    address: "",
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(getAllVehicles(token)); // Fetch all vehicles via Redux action
    }
  }, [token, dispatch]);

  // Add a new vehicle form group
  const handleAddVehicle = () => {
    setVehicles([...vehicles, { vehicleName: "", vehicleColor: "", vehicleNumber: "" }]);
  };

  // Add a new address form group
  const handleAddAddress = () => {
    setAddresses([...addresses, { address: "", latitude: "", longitude: "" }]);
  };

  // Remove a vehicle form group
  const handleRemoveVehicle = (index) => {
    const newVehicles = vehicles.filter((_, i) => i !== index);
    setVehicles(newVehicles);
  };

  // Remove an address form group
  const handleRemoveAddress = (index) => {
    const newAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(newAddresses);
  };

  // Handle change in vehicle fields, including auto-filling vehicle colors
  const handleVehicleChange = (index, field, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;

    // If vehicle name is selected, auto-fill vehicle colors
    if (field === "vehicleName") {
      const selectedVehicle = all_vehicles.find(
        (vehicleOption) => vehicleOption._id === value
      );

      if (selectedVehicle) {
        // Auto-fill all vehicle colors, comma-separated
        newVehicles[index]["vehicleColor"] = selectedVehicle.vehicleColors.join(", ");
      }
    }

    setVehicles(newVehicles);
  };

  // Handle change in address fields
  const handleAddressChange = (index, field, value) => {
    const newAddresses = [...addresses];
    newAddresses[index][field] = value;
    setAddresses(newAddresses);
  };

  // Handle change in user form fields
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create payload
    const payload = {
      ...formData, // User details including user address
      vehicleDetails: vehicles, // Vehicle details from the dynamic form
      addresses, // Address details from the dynamic form
    };

    dispatch(addUser(payload, token));
    setTimeout(() => {
      navigate("/AllUserManagement");
    }, 3000);
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New User" />

        {/* Form for creating user */}
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-[30%_68%] gap-8">
            {/* Left Column */}
            <div className="container text-start">
              <div className="mb-5">
                <label
                  htmlFor="name"
                  className="block mb-2 text-xl mt-2 font-medium text-gray-900"
                >
                  Customer Details
                </label>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Customer Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter customer name"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleFormChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter phone number"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="AlternateNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Alternate Phone Number (Optional)
                </label>
                <input
                  type="text"
                  id="AlternateNumber"
                  name="AlternateNumber"
                  value={formData.AlternateNumber}
                  onChange={handleFormChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter alternate phone number"
                />
              </div>

              {/* <div className="mb-5">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  User Address
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleFormChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter user address"
                  required
                />
              </div> */}
            </div>

            {/* Right Column - Vehicle Details Section */}
            <div className="container text-start">
              <label className="block text-xl mt-2 font-medium mb-2">Vehicle Details</label>
              <div>
                {vehicles.map((vehicle, index) => (
                  <div key={index} className="flex items-center gap-2">
                    {/* Vehicle Dropdown */}
                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Vehicle Maker & Model
                      </label>
                      <select
                        value={vehicle.vehicleName}
                        onChange={(e) =>
                          handleVehicleChange(
                            index,
                            "vehicleName",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                      >
                        <option value="">Select Vehicle</option>
                        {all_vehicles &&
                          all_vehicles.map((vehicleOption) => (
                            <option
                              key={vehicleOption._id}
                              value={vehicleOption._id}
                            >
                              {vehicleOption.maker}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* Vehicle Color */}
                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Vehicle Color
                      </label>
                      <input
                        type="text"
                        value={vehicle.vehicleColor}
                        onChange={(e) =>
                          handleVehicleChange(
                            index,
                            "vehicleColor",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                        placeholder="Enter vehicle color"
                      />
                    </div>

                    {/* Vehicle Number */}
                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Vehicle Number
                      </label>
                      <input
                        type="text"
                        value={vehicle.vehicleNumber}
                        onChange={(e) =>
                          handleVehicleChange(
                            index,
                            "vehicleNumber",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                        placeholder="Enter vehicle number"
                      />
                    </div>

                    <AiOutlineClose
                      className="text-black cursor-pointer h-20 w-20 ml-2"
                      onClick={() => handleRemoveVehicle(index)}
                    />
                  </div>
                ))}

                <Button
                  className="flex items-center bg-black text-white hover:bg-[#681312] hover:text-white border border-transparent"
                  onClick={handleAddVehicle}
                >
                  <PlusOutlined /> Add Vehicle
                </Button>
              </div>

              <label className="block text-xl mt-2 font-medium mb-2">Address Details</label>
              <div>
                {addresses.map((address, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Address
                      </label>
                      <input
                        type="text"
                        value={address.address}
                        onChange={(e) =>
                          handleAddressChange(
                            index,
                            "address",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                        placeholder="Enter Address"
                      />
                    </div>

                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Latitude
                      </label>
                      <input
                        type="text"
                        value={address.latitude}
                        onChange={(e) =>
                          handleAddressChange(
                            index,
                            "latitude",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                        placeholder="Enter Latitude"
                      />
                    </div>

                    <div className="mb-2 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-900">
                        Longitude
                      </label>
                      <input
                        type="text"
                        value={address.longitude}
                        onChange={(e) =>
                          handleAddressChange(
                            index,
                            "longitude",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                        placeholder="Enter Longitude"
                      />
                    </div>

                    <AiOutlineClose
                      className="text-black cursor-pointer h-20 w-20 ml-2"
                      onClick={() => handleRemoveAddress(index)}
                    />
                  </div>
                ))}

                <Button
                  className="flex items-center bg-black text-white hover:bg-[#681312] hover:text-white border border-transparent"
                  onClick={handleAddAddress}
                >
                  <PlusOutlined /> Add Address
                </Button>
              </div>
            </div>
          </div>

          <div className="flex justify-left mt-6">
            <button
              type="submit"
              className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create User
            </button>
          </div>
        </form>
      </MainSection>
    </div>
  );
}

export default CreateUser;
